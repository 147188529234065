import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import { useNavigate } from "react-router-dom";

const FeaturedEventRenderItem = ({ data, hendEnabled,onClickFeaturedStatus, adminEventEdit }) => {
  const navigate = useNavigate();
  const onClickEventDetails = (item) => {
    navigate("/Eventdetails", {
      state: {
        eventId: item.eventId,
      },
    });
  };
  const handleEditClick = (currentUsers) => {
    navigate("/EventUpdate", {
      state: {
        eventId: currentUsers.eventId,
        eventTypeId: currentUsers.eventTypeId,
        adminEventEdit: adminEventEdit,
      },
    });
  };
  return (
    <div>
      <div style={{ maxHeight: "500px" }} className="scrollable-container">
        {data?.map((item, index) => (
          <div key={index}>
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                onClick={() => onClickEventDetails(item)}
                className="lastWeekEndDivGreyColor"
                style={{
                  cursor: "pointer",
                  width: "8%",
                }}
              >
                <img
                  src={item.eventImages ?? Icons.ic_AY_logo}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                onClick={() => onClickEventDetails(item)}
                className="fontSize16WithoutFlex"
                style={{
                  cursor: "pointer",
                  width: "14%",
                }}
              >
                {item.eventTypeName}
              </div>
              <div
                onClick={() => onClickEventDetails(item)}
                className="fontSize16WithoutFlex"
                style={{
                  cursor: "pointer",
                  width: "18%",
                }}
              >
                {item.eventName}
              </div>

              <div
                onClick={() => onClickEventDetails(item)}
                style={{
                  cursor: "pointer",

                  width: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "0.25rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <img
                    className="icon_style_list"
                    alt=""
                    src={Icons.ic_calendar}
                  />
                  <div style={{}} className="itemTextDisplay">
                    {item.eventStartDate + " , " + item.eventStartTime}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <img
                    className="icon_style_list"
                    alt=""
                    src={Icons.ic_location}
                  />
                  <div className="itemTextDisplay">
                    {item.birthPlace == "" ? "-" : item.venueAddress}
                  </div>
                </div>
              </div>
              <div style={{ width: "10%" }}>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(item)}
                  style={{
                    width: "20px",
                    cursor: "pointer",
                    filter: "brightness(0) invert(1)",
                  }}
                />
              </div>
              <div
                style={{
                  width: "15%",
                }}
                className="fontSize16WithoutFlex"
              >
                <div style={{ cursor: "pointer" }} className="user_Status">
                  <button
                    onClick={() => hendEnabled(item, index)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: item.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: item.isEnabled ? Colors.darkGreen : Colors.darkRed,
                    }}
                  >
                    {item.isEnabled ? "Activate" : "Deactivate"}
                  </button>
                </div>
              </div>

              <div
                style={{
                  width: "15%",
                }}
                className="fontSize16WithoutFlex"
              >
                <div style={{ cursor: "pointer" }} className="user_Status">
                  <button
                    onClick={() => onClickFeaturedStatus(item, index)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: item.isFeatured
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: item.isFeatured ? Colors.darkGreen : Colors.darkRed,
                    }}
                  >
                    {item.isFeatured ? "Activate" : "Deactivate"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default FeaturedEventRenderItem;
