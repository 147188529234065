import React, { useEffect, useRef } from "react";
import Header from "../../Components/Header/Header";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import { useState } from "react";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import CustomCategorieScreen from "../../Components/CustomCategoryScreen/CustomCategoryScreen";
import AddSubcategory from "./ AddnewSubcategory";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import {
  apiCallDeleteInterestCategory,
  apiCallGetAdminInterestCategoryList,
  apiCallGetCategoryStatusChange,
  onAPiCallsearchInterestCategory,
} from "../../Core/Apicall";
import UseDebounse from "../../Components/UseDebounse";
import EmptyData from "../../Components/EmptyData/EmptyData";

function Categorie({}) {
  const dispatch = useDispatch();
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const [editData, setEditData] = useState(null);
  const [Categories, setCategories] = useState(false);
  const [showcategory, setshowcategory] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [categoriesAllData, setCategoriesAllData] = useState();
  const [categoryshow, setcategoryshow] = useState(true);
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState("By Status");
  const [categoryItemSelect, setCategoryItemSelect] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [categoryTotalActive, setCategoryTotalActive] = useState(0);
  const [categoryTotalInActive, setCategoryTotalInActive] = useState(0);
  const [categoryTotalCount, setCategoryTotalCount] = useState(0);
  const debouncedInput = UseDebounse(searchValue, 500);
  const [initialPage, setInitialPage] = useState(0);

  useEffect(() => {
    onApiCallCategoryList(0);
  }, []);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    searchFilterFunction(searchValue, 0);
  }, [debouncedInput]);

  const onApiCallCategoryList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetAdminInterestCategoryList(param);
      setCategoryTotalActive(res.allActiveCategories);
      setCategoryTotalInActive(res.allDeActiveCategories);
      setCategoryTotalCount(res.allCategoriesTotal);
      setDropdownLabelSelect("By Status");
      setInitialPage(selectedPage ?? 0);
      setCategoriesData(res);
      setCategoriesAllData(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const addNewCategory = () => {
    setShowModal(true);
    setEditData(null);
  };
  const handleCloseModal = () => {
    setEditData();
    setShowModal(false);
  };

  const handleEditClick = (selectedCategory) => {
    setShowModal(true);
    setEditData(selectedCategory);
  };

  const handleSelect = (status) => {
    setDropdownLabelSelect(status);
    if (status === "All") {
      setCategoriesData(categoriesAllData);
    } else {
      const filteredData = categoriesAllData.categories.filter((item) =>
        status === "Activate"
          ? item.isEnabled === true
          : item.isEnabled === false
      );
      setCategoriesData({ ...categoriesAllData, categories: filteredData });
    }
  };

  const handleInputChange = (e) => {
    // setDropdownLabelSelect("All");
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect("By Status");
    const param = {
      searchQuery: text,
      offset: page + 1,
      limit: 15,
    };
    dispatch(setLoadingTrue());
    try {
      const res = await onAPiCallsearchInterestCategory(param);
      setInitialPage(page);
      setCategoriesData(res);
      setCategoriesAllData(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const onClickToggleStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      categoryId: data.categoryId,
      isEnabled: !data.isEnabled,
    };
    try {
      await apiCallGetCategoryStatusChange(param);
      dispatch(setLoadingFalse());
      if (data.isEnabled) {
        setCategoryTotalActive((prev) => prev - 1);
        setCategoryTotalInActive((prev) => prev + 1);
      } else {
        setCategoryTotalActive((prev) => prev + 1);
        setCategoryTotalInActive((prev) => prev - 1);
      }

      setCategoriesData((prevData) => {
        const updatedData = prevData.categories.map((item) => {
          if (item.categoryId === data.categoryId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, categories: updatedData };
      });
      setCategoriesAllData((prevData) => {
        const updatedData = prevData.categories.map((item) => {
          if (item.categoryId === data.categoryId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, categories: updatedData };
      });
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const hendDelete = async (selectedCategory) => {
    dispatch(setLoadingTrue());
    const param = {
      categoryId: selectedCategory.categoryId,
    };
    try {
       await apiCallDeleteInterestCategory(param);
      if (selectedCategory.isEnabled) {
        setCategoryTotalActive((prev) => prev - 1);
      } else {
        setCategoryTotalInActive((prev) => prev - 1);
      }
      setCategoryTotalCount((prev) => prev - 1);
      setCategoriesData((prevData) => ({
        ...prevData,
        categories: prevData.categories.filter((item) => item.categoryId !== selectedCategory.categoryId),
      }));
      setCategoriesAllData((prevData) => ({
        ...prevData,
        categories: prevData.categories.filter((item) => item.categoryId !== selectedCategory.categoryId),
      }));
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSubcategory = (category) => {
    setCategoryItemSelect(category);
    setshowcategory(true);
  };

  return (
    <div className="Categorie_s">
      <Header name={"Category"} />
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div style={{}} className="astrologer-inner">
            <div className="frame-container">
              {/* <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div> */}
              <div className="frame-div">
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Category
                  </div>
                  <div className="fontSize24">{categoryTotalCount}</div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Activate
                  </div>
                  <div
                    style={{ color: Colors.darkGreen }}
                    className="fontSize24"
                  >
                    {categoryTotalActive}
                  </div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Deactivate
                  </div>
                  <div
                    style={{ color: Colors.darkBlue }}
                    className="fontSize24"
                  >
                    {categoryTotalInActive}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="category_button">
              <button onClick={addNewCategory}>Add new category </button>
            </div>
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>

            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showcategory && (
        <AddSubcategory
          shows={showcategory}
          setshowcategory={setshowcategory}
          editData={editData}
          categoryItemSelect={categoryItemSelect}
          handleClose={handleCloseModal}
        />
      )}
      {showModal && (
        <Modal
          show={showModal}
          editData={editData}
          handleClose={handleCloseModal}
          pageRefresh={() => onApiCallCategoryList(0)}
          Categories={Categories}
          setCategories={setCategories}
        />
      )}
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div
            className="user_title"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            Category list
            {/* <img
              onClick={onApiCallCategoryList}
              src={Icons.ic_refresh}
              className="updateicone"
            /> */}
          </div>
          <div style={{ background: "rgba(255, 255, 255, 0.1)" }}>
            <nav
              className="grid_colum grid_7 fontSize16WithoutFlex"
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                padding: "20px",
              }}
            >
              <div className="category_w">Category img</div>
              <div className="category_w">Category name</div>
              <div className="category_w">Status</div>
              <div className="category_w">Edit</div>
              <div className="category_w">Subcategory</div>
              <div className="category_w">Delete</div>
            </nav>
            <CustomCategorieScreen
              currentCategories={categoriesData?.categories}
              onClickToggleStatusChange={onClickToggleStatusChange}
              onEditClick={handleEditClick}
              hendDelete={hendDelete}
              handleSubcategory={handleSubcategory}
              categoryshow={categoryshow}
            />
          </div>
        </div>
      </div>

      {categoriesData?.categories?.length === 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <EmptyData />
        </div>
      )}

      {categoriesData?.categories?.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(categoriesData?.totalCount / 15)}
            initialPage={initialPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected);
              } else {
                onApiCallCategoryList(selected);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
export default Categorie;
