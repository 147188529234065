import React from "react";
import styles from "../../../Style";
import Icons from "../../../../Constants/Icons";
const Hostdetails = ({ eventDetails }) => {
    console.log('EventDetails:', eventDetails);
    return (
        <div className="achievements">
            <div >
                <div className="margintop_20" style={styles.menu_ic}>
                <div className="astrologerText">Host details</div>
                    <div style={styles.Hostdetails} >
                        <div style={styles.menu_ic}>
                            <div className="astrologerText">Event Details</div>

                            <div>
                                <div
                                    style={{ display: "flex", gap: "5px", alignItems: "center", marginBottom: "1rem" }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            width: "70px",
                                            height: "70px",
                                        }}
                                    >
                                        <img
                                            style={{ width: "70px", height: "70px", borderRadius: "70px" }}
                                            src={eventDetails?.eventDetail.eventImages ?? Icons.Headshot}
                                        />
                                    </div>
                                    <div> {eventDetails?.eventDetail.eventName}</div>
                                </div>

                            </div>
                            <table className="table">


                                <tr>
                                    <th>StartDate:</th>
                                    <td>
                                        {eventDetails?.eventDetail.eventStartDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>StartTime:</th>
                                    <td>
                                        {eventDetails?.eventDetail.eventStartTime}
                                    </td>
                                </tr>
                                <tr>
                                    <th>TypeName:</th>
                                    <td>
                                        {eventDetails?.eventDetail.eventTypeName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Visibility:</th>
                                    <td>
                                        {eventDetails?.eventDetail.eventVisibility}
                                    </td>
                                </tr>
                                <tr>
                                    <th>requirements:</th>
                                    <td>
                                        {eventDetails?.eventDetail.requirements}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description:</th>
                                    <td>
                                        {eventDetails?.eventDetail.eventDescription}
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div style={styles.menu_ic}>
                            <div className="astrologerText">Event Creator</div>
                            <div>
                                <div
                                    style={{ display: "flex", gap: "5px", alignItems: "center" }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            width: "70px",
                                            height: "70px",
                                        }}
                                    >
                                        <img
                                            style={{ width: "70px", height: "70px", borderRadius: "70px" }}
                                            src={eventDetails?.eventCreator.eventCreatorProfile ?? Icons.Headshot}
                                        />
                                    </div>
                                    <div> {eventDetails?.eventCreator.eventCreatorName}</div>
                                </div>

                            </div>
                            <table className="table">
                                <tr>
                                    <th>CreatorUserName:</th>
                                    <td>
                                        {eventDetails?.eventCreator.eventCreatorUserName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>CreatorMobile:</th>
                                    <td>
                                        {eventDetails?.eventCreator.eventCreatorMobile}
                                    </td>
                                </tr>
                                <tr>
                                    <th>CreatorEmail:</th>
                                    <td>
                                        {eventDetails?.eventCreator.eventCreatorEmail}
                                    </td>
                                </tr>
                                <tr>
                                    <th>CreatorCity:</th>
                                    <td>
                                        {eventDetails?.eventCreator.eventCreatorCity}
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div style={styles.menu_ic}>
                            <div className="astrologerText">Venue Detail</div>
                            <table className="table">
                                <tr>
                                    <th>VenueName:</th>
                                    <td>
                                        {eventDetails?.venueDetail.venueName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>VenueAddress:</th>
                                    <td>
                                        {eventDetails?.venueDetail.venueAddress}
                                    </td>
                                </tr>

                            </table>
                            <div className="astrologerText">Event Mode</div>
                            <table className="table">
                                <tr>
                                    <th>CategoryName</th>
                                    <td>
                                        {eventDetails?.eventMode.categoryName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>ExtraBonusSpots:</th>
                                    <td>
                                        {eventDetails?.eventMode.extraBonusSpots}
                                    </td>
                                </tr>
                                <tr>
                                    <th>NoOfSpots:</th>
                                    <td>
                                        {eventDetails?.eventMode.noOfSpots}
                                    </td>
                                </tr>



                            </table>


                        </div>
                        <div style={styles.menu_ic}>
                            <div className="astrologerText">All Event</div>
                            <table className="table">
                                <tr>
                                    <th>exceptFirst</th>
                                    <td>
                                        {eventDetails?.exceptFirst}
                                    </td>
                                </tr>
                                <tr>
                                    <th>ExceptSecond:</th>
                                    <td>
                                        {eventDetails?.exceptSecond}
                                    </td>
                                </tr>
                                <tr>
                                    <th>ExceptThird:</th>
                                    <td>
                                        {eventDetails?.exceptThird}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Latitude:</th>
                                    <td>
                                        {eventDetails?.latitude}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Longitude:</th>
                                    <td>
                                        {eventDetails?.longitude}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div style={styles.menu_ic}>
                            <div className="astrologerText">Event Pricing</div>
                            <table className="table">
                                <tr>
                                    <th>VIPPrice:</th>
                                    <td>
                                        {eventDetails?.eventPricing.VIPPrice}
                                    </td>
                                </tr>
                                <tr>
                                    <th>EconomyPrice:</th>
                                    <td>
                                        {eventDetails?.eventPricing.economyPrice}
                                    </td>
                                </tr>
                                <tr>
                                    <th>EventEntry:</th>
                                    <td>
                                        {eventDetails?.eventPricing.eventEntry}
                                    </td>
                                </tr>
                                <tr>
                                    <th>LatestDateForPurchase:</th>
                                    <td>
                                        {eventDetails?.eventPricing.latestDateForPurchase}
                                    </td>
                                </tr>
                                <tr>
                                    <th>TicketPageLink:</th>
                                    <td>
                                        {eventDetails?.eventPricing.ticketPageLink}
                                    </td>
                                </tr>

                            </table>


                        </div>
                        <div style={styles.menu_ic}>
                            <div className="astrologerText">Schedule Event</div>
                            <table className="table">
                                <tr>
                                    <th>RepeatType:</th>
                                    <td>
                                        {eventDetails?.scheduleEvent.repeatType}
                                    </td>
                                </tr>
                                <tr>
                                    <th>StartDate:</th>
                                    <td>
                                        {eventDetails?.scheduleEvent.startDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>NextEvent:</th>
                                    <td>
                                        {eventDetails?.scheduleEvent.nextEvent}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default Hostdetails;
