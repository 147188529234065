import React from "react";
import styles from "../../../Style";
import Icons from "../../../../Constants/Icons";
const UserProfiledetails = ({ userDetails }) => {
    console.log("fjgjgi", userDetails)
    return (
        <div className="achievements">
            <div >
                <div className="margintop_20" style={styles.menu_ic}>

                    <div style={styles.Hostdetails1} >
                        <div style={styles.menu_ic}>
                            <table className="table">


                                <tr>
                                    <th>BirthDate:</th>
                                    <td>
                                        {userDetails?.birthDate}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description:</th>
                                    <td>
                                        {userDetails?.description}
                                    </td>
                                </tr>
                                <tr>
                                    <th>User name:</th>
                                    <td>
                                        {userDetails?.userName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Social Media:</th>
                                    <td>
                                        {userDetails?.socialMedia.length != 0 && (
                                            <div style={{ marginTop: "10px" }}>
                                                {userDetails?.socialMedia?.map((link, index) => (
                                                    <a
                                                        key={index}
                                                        href={link?.socialMediaLink}
                                                        rel="noopener noreferrer"
                                                        className="social-link"
                                                    >
                                                        <img
                                                            src={link?.socialImageUrl}
                                                            style={{ width: "30px", marginLeft: "10px" }}
                                                            className="social-icon"
                                                        />
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </td>
                                </tr>


                            </table>

                        </div>
                        <div style={styles.menu_ic}>
                            <table className="table">

                                <tr>
                                    <th>User email:</th>
                                    <td>
                                        {userDetails?.userEmail}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Mobile:</th>
                                    <td>
                                        {userDetails?.mobile}
                                    </td>
                                </tr>
                                <tr>
                                    <th>User type:</th>
                                    <td>
                                        {userDetails?.userType}
                                    </td>
                                </tr>

                            </table>

                        </div>
                        {/* <div style={styles.menu_ic}>
                            <table className="table">

                                <tr>
                                    <th>Followers:</th>
                                    <td>
                                        {userDetails?.followers}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Followers:</th>
                                    <td>
                                        {userDetails?.followers}
                                    </td>
                                </tr>
                              
                            </table>

                        </div> */}

                    </div>

                </div>
            </div>
        </div>

    );
};

export default UserProfiledetails;
