import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import { useState, useEffect } from "react";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import CustomupcomingeventsScreen from "../../Components/CustomDashboardScreen/Upcomingevents";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import {
  apiCallAdminGetAllFeatured,
  apiCalltoggleEventStatus,
  onAPiCallsearchEvent,
  onAPiCallToggleEventFeatured,
} from "../../Core/Apicall";
import EmptyData from "../../Components/EmptyData/EmptyData";
import UseDebounse from "../../Components/UseDebounse";
import FeaturedEventRenderItem from "../../Components/CustomDashboardScreen/FeaturedEventRenderItem";
function FeaturedEvent() {
  const dispatch = useDispatch();
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const [initialPage, setInitialPage] = useState(0);
  const [Evente, setEvente] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState("By Status");
  const [EventTypeAllData, setEventTypeAllData] = useState();

  const [totalCount, setTotalCount] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalInActive, setTotalInActive] = useState(0);

  const debouncedInput = UseDebounse(searchValue, 500);

  const handleSelect = (status) => {
    setDropdownLabelSelect(status);
    if (status === "All") {
      setEvente(EventTypeAllData);
    } else {
      const filteredData = EventTypeAllData.data.filter((item) =>
        status === "Activate"
          ? item.isEnabled === true
          : item.isEnabled === false
      );
      setEvente({ ...EventTypeAllData, data: filteredData });
    }
  };

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    searchFilterFunction(searchValue, 0);
  }, [debouncedInput]);

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const searchFilterFunction = async (text, page) => {
    dispatch(setLoadingTrue());
    setDropdownLabelSelect("By Status");

    const param = {
      weekSet: "Featured",
      searchQuery: text,
      offset: page + 1,
      limit: 15,
    };

    try {
      const res = await onAPiCallsearchEvent(param);
      console.log("res  serach ev", res.data);
      setEvente(res);
      setInitialPage(page);
      setEventTypeAllData(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  useEffect(() => {
    onApiEventTypeList(0);
  }, []);

  const onApiEventTypeList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15,
    };
    try {
      const response = await apiCallAdminGetAllFeatured(param);
      console.log("responseresponseresponseresponse",response);
      
      setTotalCount(response?.allEvent);
      setTotalActive(response?.allActiveEvent);
      setTotalInActive(response?.allDeActiveEvent);
      setInitialPage(selectedPage ?? 0);
      setDropdownLabelSelect("By Status");
      setEvente(response);
      setEventTypeAllData(response);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Error fetching event types:", error);
      dispatch(setLoadingFalse());
    }
  };

  const onClickFeaturedStatus = async (data) =>{
    dispatch(setLoadingTrue());
    const param = {
      eventId: data.eventId,
      isFeatured: !data.isFeatured,
    };
    try {
      const res = await onAPiCallToggleEventFeatured(param);
      if (data.isFeatured) {
        setTotalActive((pre) => pre - 1);
        setTotalInActive((pre) => pre + 1);
      } else {
        setTotalActive((pre) => pre + 1);
        setTotalInActive((pre) => pre - 1);
      }

      setEvente((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.eventId === data.eventId) {
            return { ...item, isFeatured: !item.isFeatured };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      setEventTypeAllData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.eventId === data.eventId) {
            return { ...item, isFeatured: !item.isFeatured };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log("errorerrorerror", error);
      dispatch(setLoadingFalse());
    }
  }

  const hendEnabled = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled,
    };
    try {
      const res = await apiCalltoggleEventStatus(param);
      if (data.isEnabled) {
        setTotalActive((pre) => pre - 1);
        setTotalInActive((pre) => pre + 1);
      } else {
        setTotalActive((pre) => pre + 1);
        setTotalInActive((pre) => pre - 1);
      }

      setEvente((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      setEventTypeAllData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.eventId === data.eventId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log("errorerrorerror", error);
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div>
      <Header name={"Featured Events"} />
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div style={{}} className="astrologer-inner">
            <div className="frame-container">
              {/* <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div> */}
              <div className="frame-div">
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Featured Events
                  </div>
                  <div className="fontSize24">{totalCount}</div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Featured Activate
                  </div>
                  <div
                    style={{ color: Colors.darkGreen }}
                    className="fontSize24"
                  >
                    {totalActive}
                  </div>
                </div>
                <div style={{}} className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Featured Deactivate
                  </div>
                  <div
                    style={{ color: Colors.darkBlue }}
                    className="fontSize24"
                  >
                    {totalInActive}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>

            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div
            className="user_title"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            Featured Events list
            {/* <img
              onClick={handleupdate}
              src={Icons.ic_refresh}
              className="updateicone"
            /> */}
          </div>

          <div className="upcoming_list">
            <div
              style={{
                width: "100%",
                // borderRadius: "16px",
                background: "rgba(255, 255, 255, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // padding: "1rem",
                gap: "1rem",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  //   borderRadius: "8px",
                  //   background: "rgba(255, 255, 255, 0.1)",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: 1,
                    backgroundColor: Colors.borderColor,
                    borderBottom: Colors.darkBorderColor,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "rgba(255, 255, 255, 0.5)",
                    justifyContent: "space-between",
                    padding: "1rem",
                  }}
                >
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "8%",
                    }}
                  >
                    image
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "14%",
                    }}
                  >
                    Name
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "18%",
                    }}
                  >
                    Events Name
                  </div>

                  <div
                    style={{
                      width: "20%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Date & times
                  </div>
                  <div
                    style={{
                      width: "10%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Edit
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Status
                  </div>
                  <div
                    style={{
                      width: "15%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Featured
                  </div>
                </div>
                <div
                  style={{ maxHeight: "500px" }}
                  className="scrollable-container transform_data"
                >
                  <FeaturedEventRenderItem
                  onClickFeaturedStatus={onClickFeaturedStatus}
                    hendEnabled={hendEnabled}
                    data={Evente?.data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Evente?.data.length == 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <EmptyData />
        </div>
      )}
      {Evente?.data.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Evente?.totalCount / 15}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected);
              } else {
                onApiEventTypeList(selected);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
export default FeaturedEvent;
