import { blue } from "@mui/material/colors";

const Colors = {
  black: "#000000",
  white: "#FFFFFF",
  secondary: "#818181",
  primary: "#FFFFFF",
  darkBlack: "#1E1E25",
  tabBackgroundColor: "rgba(255, 255, 255, 0.1)",
  mainBlue01: "rgba(46, 144, 250, 0.1)",
  mainOrange01: "rgba(247, 144, 9, 0.1)",
  mainGreen40: "rgba(18, 183, 106, 0.4)",
  mainRed01: "rgba(240, 68, 56, 0.1)",
  mainGreen0: "rgba(18, 183, 106, 0)",
  mainGreen01: "rgba(18, 183, 106, 0.1)",
  mainOrange40: "rgba(247, 144, 9, 0.4)",
  mainOrange0: "rgba(247, 144, 9, 0)",
  mainOrange: "rgba(247, 144, 9, 1)",
  mainGreen: "#12B76A",
  mainBlue40: "rgba(46, 144, 250, 0.4)",
  mainBlue0: "rgba(46, 144, 250, 0)",
  mainBlue: "rgba(46, 144, 250, 1)",
  // mainBlue: "rgba(46, 144, 250, 1)",
  mainRed40: "rgba(240, 68, 56, 1)",

  mainLightBlue70: "rgba(46, 144, 250, 0.7)",
  mainLightBlue0: "rgba(46, 144, 250, 0)",
  darkGreen: "#12b76a",
  greenLight01: "rgba(18, 183, 106, 1)",
  darkBlue: "#2e90fa",
  darkOrange: "#f79009",
  mainDarkColor: "rgba(6, 101, 228, 0.3)",
  darkRed: "#f04438",
  darkBorderColor: "1px solid rgba(255, 255, 255, 0.1)",
  borderColor: "rgba(255, 255, 255, 0.1)",
  greyColor50: "rgba(255, 255, 255, 0.5)",

  orangeRating:'#FF910F',

  lightGrey01: "rgba(138, 138, 138, 0.1)",
  darkGrey: "#8A8A8A",
  Blue:"rgba(10, 132, 255, 1)",
  Reddrak:"rgba(255, 69, 58, 1)",
  dark_black:"rgba(255, 255, 255, 0.1)"
};

export default Colors;
