import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import Loader from "../../Components/Loader/Loader";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import "./auth.css";
import { postApiLoginAdmin } from "../../Core/Apicall";
import { setProfileData } from "../../Config/Actions/profileActions";
const Login = ({ setIsLoggedIn, setForgotNavigate }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("admin@yopmail.com");
  const [newPassword, setNewPassword] = useState("Test@123");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const dispatch = useDispatch();
  const [valid, setvalid] = useState(
    {
      email: true,
      password: true,

    }
  );
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (newPassword) => {
    return newPassword.length >= 8;
  };

  useEffect(() => {
    navigate("/");
  }, []);

  const handleLogin = async (e) => {
    let isValid = true;
    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      setvalid((prevState) => ({
        ...prevState,
        email: false,
      }));
      isValid = false;
    } else {
      setEmailError("");
      setvalid((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (!validatePassword(newPassword)) {
      setPasswordError("Password must be at least 8 characters long");
      setvalid((prevState) => ({
        ...prevState,
        password: false,
      }));
      isValid = false;
    } else {
      setPasswordError("");
      setvalid((prevState) => ({
        ...prevState,
        password: true,
      }));
    }

    if (isValid) {
      try {
        dispatch(setLoadingTrue(true));
        const param = {
          email: email,
          password: newPassword,
        };

        const res = await postApiLoginAdmin(param);
        setIsLoggedIn(true);
        dispatch(setProfileData(res));
        dispatch(setLoadingFalse(true));
      } catch (error) {
        dispatch(setLoadingFalse(true));
        console.log("error", error);
        alert(error);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 7,
          marginTop: "0px",
          alignItems: "center",
        }}
      >
        <img
          src={Icons.ic_AY_logo}
          style={{ objectFit: "contain", height: "5.6rem", width: "5.6rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          background: Colors.mainDarkColor,

          borderRadius: "3px",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            padding: "10px 20px 10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="title_reset_password"
          >
            Login
          </div>
          <div className="title_password_main">Email</div>
          <div
          className={`  ${valid.email ? 'valid' : 'invalid'}`}
            style={{
              background: "#ffffff1a",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="searchTextInput"
              placeholder="Enter email"
              style={{
                width: "100%", // Ensure the input fills the parent div
              }}
            />
          </div>
          {emailError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>{emailError}</div>
          )}
          <div className="title_password_main">Enter password</div>
          <div
             className={`  ${valid.password ? 'valid' : 'invalid'}`}
            style={{
              background: "#ffffff1a",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="searchTextInput"
              placeholder="Enter password"
              style={{
                width: "100%",
              }}
            />
            <img
              onClick={() => setShowNewPassword(!showNewPassword)}
              src={showNewPassword ? Icons.hidde : Icons.show}
              style={{ cursor: "pointer", height: "0.8rem", width: "1rem" }}
            />
          </div>
          {passwordError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>
              {passwordError}
            </div>
          )}
          <div
            onClick={() => {
              setForgotNavigate(true);
              navigate("ForgotPassword", { replace: true });
            }}
            style={{
              marginTop: "0.5rem",
              color: "#2E90FA",
              fontSize: "0.7rem",
              cursor: "pointer",
              textAlign: "end",
            }}
            className="fontSize16"
          >
            Forgot Password?
          </div>
          <div
            style={{
              marginTop: "20px",
              backgroundColor: "rgb(255, 69, 58)"
            }}
            onClick={() => handleLogin()}
            className="reset_password_on_click ______++_++"
          >
            Login
          </div>
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default Login;
