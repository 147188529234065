const Icons = {
  ic_notifications: require("../Assets/Icons/ic_notifications.png"),
  ic_down: require("../Assets/Icons/ic_down.png"),
  ic_arrow_right: require("../Assets/Icons/ic_arrow_right.png"),
  ic_copy: require("../Assets/Icons/ic_copy.png"),
  ic_refresh: require("../Assets/Icons/ic_refresh.png"),
  ic_home: require("../Assets/Icons/ic_home.png"),
  ic_astrologers: require("../Assets/Icons/ic_astrologers.png"),
  ic_sessions: require("../Assets/Icons/ic_sessions.png"),
  ic_shop: require("../Assets/Icons/ic_shop.png"),
  ic_user: require("../Assets/Icons/ic_user.png"),
  ic_customer_support: require("../Assets/Icons/ic_customer_support.png"),
  ic_coupon: require("../Assets/Icons/ic_coupon.png"),
  ic_transaction: require("../Assets/Icons/ic_transaction.png"),
  ic_regstration: require("../Assets/Icons/ic_regstration.png"),
  ic_grey_home: require("../Assets/Icons/ic_grey_home.png"),
  ic_shop_white: require("../Assets/Icons/ic_shop_white.png"),
  ic_user_white: require("../Assets/Icons/ic_user_white.png"),
  ic_cpupon_white: require("../Assets/Icons/ic_cpupon_white.png"),
  ic_customer_white: require("../Assets/Icons/ic_customer_white.png"),
  ic_sessions_white: require("../Assets/Icons/ic_sessions_white.png"),
  ic_astrologer_white: require("../Assets/Icons/ic_astrologer_white.png"),
  ic_regstration_white: require("../Assets/Icons/ic_regstration_white.png"),
  ic_transaction_white: require("../Assets/Icons/ic_transaction_white.png"),
  ic_search: require("../Assets/Icons/ic_search.png"),
  ic_shorting: require("../Assets/Icons/ic_shorting.png"),
  ic_star: require("../Assets/Icons/ic_star.png"),
  ic_white_right_arrow: require("../Assets/Icons/ic_white_right_arrow.png"),
  ic_white_left_arrow: require("../Assets/Icons/ic_white_left_arrow.png"),
  ic_sms: require("../Assets/Icons/ic_sms.png"),
  ic_calendar: require("../Assets/Icons/ic_calendar.png"),
  ic_call: require("../Assets/Icons/ic_call.png"),
  ic_location: require("../Assets/Icons/ic_location.png"),
  ic_wallet: require("../Assets/Icons/ic_wallet.png"),
  ic_white_wallet: require("../Assets/Icons/ic_white_wallet.png"),
  ic_close: require("../Assets/Icons/ic_close.png"),
  ic_more: require("../Assets/Icons/ic_more.png"),
  ic_blue_messages: require("../Assets/Icons/ic_blue_messages.png"),
  ic_orange_duration: require("../Assets/Icons/ic_orange_duration.png"),
  ic_green_eye: require("../Assets/Icons/ic_green_eye.png"),
  ic_watch: require("../Assets/Icons/ic_watch.png"),
  ic_grey_clock: require("../Assets/Icons/ic_grey_clock.png"),
  ic_add: require("../Assets/Icons/ic_add.png"),
  ic_grey_calendar: require("../Assets/Icons/ic_grey_calendar.png"),
  ic_grey_watch: require("../Assets/Icons/ic_grey_watch.png"),
  ic_grey_down: require("../Assets/Icons/ic_grey_down.png"),
  ic_send_message: require("../Assets/Icons/ic_send_message.png"),
  ic_play_pause: require("../Assets/Icons/ic_play_pause.png"),
  ic_wave_sound: require("../Assets/Icons/ic_wave_sound.png"),
  ic_audio_pause: require("../Assets/Icons/ic_audio_pause.png"),
  ic_wave_stop: require("../Assets/Icons/ic_wave_stop.png"),
  hide_password: require("../Assets/Icons/hide_password.png"),
  show_password: require("../Assets/Icons/show_password.png"),
  ic_upload_photo: require("../Assets/Icons/ic_upload_photo.png"),
  ic_edit_info: require("../Assets/Icons/ic_edit_info.png"),
  ic_AY_logo: require("../Assets/Icons/AY.png"),
  ic_logout: require("../Assets/Icons/logout.png"),
  ic_devices: require("../Assets/Icons/ic_devices.png"),
  ic_red_close: require("../Assets/Icons/ic_red_close.png"),
  ic_password_successufly: require("../Assets/Icons/ic_password_successufly.png"),
  ic_white_notification: require("../Assets/Icons/ic_white_notification.png"),
  ic_notification_grey: require("../Assets/Icons/ic_notification_grey.png"),
  ic_link: require("../Assets/Icons/ic_link.png"),
  ic_linky: require("../Assets/Icons/y.png"),
  ic_linkx: require("../Assets/Icons/x.png"),
  Fileicon: require("../Assets/Icons/flie.png"),
  Vector: require("../Assets/Icons/Vector.png"),
  // artfulto icon
  hidde: require("../Assets/Icons/hidde.png"),
  show: require("../Assets/Icons/show.png"),
  Categorie: require("../Assets/Icons/Categorie.png"),
  Categorie1: require("../Assets/Icons/Categorie1.png"),
  event: require("../Assets/Icons/event.png"),
  eventm:require("../Assets/Icons/eventn.png"),
  delete:require("../Assets/Icons/delete.png"),
  edit:require("../Assets/Icons/edit.png"),
  uplod:require("../Assets/Icons/uplod.png"),
  Headshot:require("../Assets/Images/Headshot.png"),
  instagram:require("../Assets/Icons/instagram.png"),
  fb:require("../Assets/Icons/fb.png"),
  twiter:require("../Assets/Icons/twiter.png"),
  snp:require("../Assets/Icons/snp.png"),
  tit:require("../Assets/Icons/tit.png"),
  Achievements:require("../Assets/Images/Achievements.png"),
  Achievements1:require("../Assets/Images/Achievements1.png"),
  Achievements2:require("../Assets/Images/Achievements2.png"),
  Achievements3:require("../Assets/Images/Achievements3.png"),
  event_a:require("../Assets/Images/event_a.png"),
  event_b:require("../Assets/Images/event_b.png"),
  Checkin:require("../Assets/Images/Check-in.png"),
  Checkin1:require("../Assets/Images/Check-in1.png"),
  Checkin2:require("../Assets/Images/Check-in2.png"),
  Checkin3:require("../Assets/Images/Check-in3.png"),
  Checkin4:require("../Assets/Images/Check-in4.png"),
  Checkin5:require("../Assets/Images/Check-in5.png"),
  Checkin6:require("../Assets/Images/Check-in6.png"),
  Subcategory:require("../Assets/Icons/Subcategory.png"),
  ic_default_user:require("../Assets/Icons/ic_default_user.png"),
  all_m:require("../Assets/Icons/all_m.png"),
  event_m:require("../Assets/Icons/event_m.png"),
  playbutton:require("../Assets/Icons/playbutton.png"),
  smile:require("../Assets/Icons/smile.png"),
  artshop:require("../Assets/Images/artshop.png"),
  artshop1:require("../Assets/Images/artshop1.png"),
  artshop2:require("../Assets/Images/artshop2.png"),
  Illustration:require("../Assets/Images/Illustration.png"),
  Illustration1:require("../Assets/Images/Illustration1.png"),
  Illustration2:require("../Assets/Images/Illustration2.png"),
  Illustration3:require("../Assets/Images/Illustration3.png"),
  mediaIcon:require("../Assets/Icons/mediaIcon.png"),
  ic_added:require("../Assets/Icons/ic_added.png"),
  Ic_addedimg:require("../Assets/Icons/Ic_addedimg.png"),
  ic_date:require("../Assets/Icons/Ic_date.png"),
  ic_door:require("../Assets/Icons/ic.door.png"),
  ic_time:require("../Assets/Icons/ic_time.png"),
  Ic_newtime:require("../Assets/Icons/Ic_newtime.png"),
  Ic_newdate:require("../Assets/Icons/ic_newdate.png"),
  ic_typewhite:require("../Assets/Icons/ic_typewhite.png"),
  ic_type:require("../Assets/Icons/ic_type.png"),
  power:require("../Assets/Icons/power.png"),
  powerwhite:require("../Assets/Icons/powerwhite.png"),
  ic_no_image:require("../Assets/Icons/ic_no_image.jpg"),
  Success:require("../Assets/Icons/Success.png"),
  ic_grey_achievement:require("../Assets/Icons/ic_grey_achievement.png"),
  ic_white_achievement:require("../Assets/Icons/ic_white_achievement.png"),
};
export default Icons;
