import React, { useState, useEffect, useRef } from "react";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Colors from "../../../Constants/Colors";
import Icons from "../../../Constants/Icons";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import CustomCategorieScreen from "../../../Components/CustomCategoryScreen/CustomSubCategoryScreen";
import Modal from "../Modal";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { useLocation } from "react-router-dom";
import {
  apiCallDeleteCategoryWiseInterest,
  apiCallGetAdminCategoryWiseInterest,
  apiCallToggleCategoryWiseInterest,
  onAPiCallsearchCategoryWiseInterest,
} from "../../../Core/Apicall";
import ModalSubCategory from "./ModalSubCategory";
import UseDebounse from "../../../Components/UseDebounse";
import EmptyData from "../../../Components/EmptyData/EmptyData";

function Subcategory({}) {
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [Categories, setCategories] = useState();
  const [subCategoryListData, setSubCategoryListData] = useState();
  const [subCategoryListAllData, setSubCategoryListAllData] = useState();
  const [Subcategoryshow, setSubcategoryshow] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState("By Status");

  const [categoryTotalActive, setCategoryTotalActive] = useState(0);
  const [categoryTotalInActive, setCategoryTotalInActive] = useState(0);
  const [categoryTotalCount, setCategoryTotalCount] = useState(0);
  const debouncedInput = UseDebounse(searchValue, 500);
  const [initialPage, setInitialPage] = useState(0);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallSubCategoryList(0);
  }, []);


  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    searchFilterFunction(searchValue, 0);
  }, [debouncedInput]);

  const onApiCallSubCategoryList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      status: null,
      categoryId: location?.state?.categoryId,
      offset: selectedPage + 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetAdminCategoryWiseInterest(param);
      setSubCategoryListData(res);
      setSubCategoryListAllData(res);
      setDropdownLabelSelect("By Status");
      setInitialPage(selectedPage ?? 0);
      setCategoryTotalActive(res.allActiveSubCat);
      setCategoryTotalInActive(res.allDeActiveSubCat);
      setCategoryTotalCount(res.allSubCatTotal);
      console.log("dd", res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleEditClick = (selectedCategory) => {
    setEditData(selectedCategory);
    setShowModal(true);
  };

  const handleSelect = (status) => {
    setDropdownLabelSelect(status);
    if (status === "All") {
      setSubCategoryListData(subCategoryListAllData);
    } else {
      const filteredData = subCategoryListAllData.data.filter((item) =>
        status === "Activate"
          ? item.isEnabled === true
          : item.isEnabled === false
      );
      setSubCategoryListData({ ...subCategoryListAllData, data: filteredData });
    }
  };

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect("By Status");
    const param = {
      searchQuery: text,
      categoryId: location?.state?.categoryId,
      offset: page + 1,
      limit: 15,
    };
    try {
      const res = await onAPiCallsearchCategoryWiseInterest(param);
      console.log("res  serach sub ", res);
      setInitialPage(page);
      setSubCategoryListData(res);
      setSubCategoryListAllData(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickToggleStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      artId: data.artId,
      isEnabled: !data.isEnabled,
    };
    try {
      await apiCallToggleCategoryWiseInterest(param);
      dispatch(setLoadingFalse());
      if (data.isEnabled) {
        setCategoryTotalActive((prev) => prev - 1);
        setCategoryTotalInActive((prev) => prev + 1);
      } else {
        setCategoryTotalActive((prev) => prev + 1);
        setCategoryTotalInActive((prev) => prev - 1);
      }

      setSubCategoryListData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.artId === data.artId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      setSubCategoryListAllData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.artId === data.artId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const hendDelete = async (selectedCategory) => {
    dispatch(setLoadingTrue());
    const param = {
      artId: selectedCategory.artId,
    };
    try {
      const res = await apiCallDeleteCategoryWiseInterest(param);
      if (selectedCategory.isEnabled) {
        setCategoryTotalActive((prev) => prev - 1);
      } else {
        setCategoryTotalInActive((prev) => prev - 1);
      }
      setCategoryTotalCount((prev) => prev - 1);
      setSubCategoryListData((prevData) => ({
        ...prevData,
        data: prevData.data.filter(
          (item) => item.artId !== selectedCategory.artId
        ),
      }));
      setSubCategoryListAllData((prevData) => ({
        ...prevData,
        data: prevData.data.filter(
          (item) => item.artId !== selectedCategory.artId
        ),
      }));
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div className="Categorie_s">
      <Header name={"Subcategory"} />
      <div style={{ alignSelf: "stretch", borderRadius: "16px" }}>
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div className="astrologer-inner">
            <div className="frame-container">
              <div className="frame-div">
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Subcategory
                  </div>
                  <div className="fontSize24">{categoryTotalCount}</div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Activate
                  </div>
                  <div
                    style={{ color: Colors.darkGreen }}
                    className="fontSize24"
                  >
                    {categoryTotalActive}
                  </div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Deactivate
                  </div>
                  <div
                    style={{ color: Colors.darkBlue }}
                    className="fontSize24"
                  >
                    {categoryTotalInActive}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>
            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalSubCategory
          show={showModal}
          editData={editData}
          handleClose={handleCloseModal}
          Categories={Categories}
          pageRefresh={() => onApiCallSubCategoryList(0)}
          setCategories={setCategories}
        />
      )}
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div className="user_title" style={{ display: "flex", gap: "10px" }}>
            Subcategory list
          </div>
          <div style={{ background: "rgba(255, 255, 255, 0.1)" }}>
            <nav
              className="grid_colum grid_7 fontSize16WithoutFlex"
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                padding: "20px",
              }}
            >
              <div className="category_w">Subcategory img</div>
              <div className="category_w">Subcategory name</div>
              <div className="category_w">Status</div>
              <div className="category_w">Edit</div>
              <div className="category_w">Delete</div>
            </nav>
            <CustomCategorieScreen
              currentCategories={subCategoryListData?.data}
              onEditClick={handleEditClick}
              onClickToggleStatusChange={onClickToggleStatusChange}
              hendDelete={hendDelete}
              Subcategoryshow={Subcategoryshow}
            />
          </div>
        </div>
      </div>

      {subCategoryListData?.data?.length === 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <EmptyData />
        </div>
      )}

      {subCategoryListData?.data?.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(subCategoryListData?.totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected);
              } else {
                onApiCallSubCategoryList(selected);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Subcategory;
