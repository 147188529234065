import React, { useEffect, useState } from "react";
import Colors from "../../../../Constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { apiCallGetUserMerch, apiCallToggleMerchStatus } from "../../../../Core/Apicall";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const Merchandise = ({ userId }) => {
  const isLoading = useSelector((state) => state.isLoading);
  const [mearchList, setMearchList] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    onApiCallMerchList();
  }, []);

  const onApiCallMerchList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetUserMerch(param);
      setMearchList(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      merchId: data?.merchId,
      isEnabled: !data?.isEnabled,
    };
    try {
      const res = await apiCallToggleMerchStatus(param);
      onApiCallMerchList()
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  return (
    <div className="achievements">

      <div className="upcoming_list">
        <div
          className="margintop_20"
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div className="astrologerText">Merchandise</div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "8px",
              border: Colors.darkBorderColor,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                backgroundColor: Colors.borderColor,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "20%",
                }}
              >
                Product image
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "20%",
                }}
              >
                Product name
              </div>
              <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Price
              </div>
              <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Status
              </div>
            </div>
            <div
              style={{ maxHeight: "350px" }}
              className="scrollable-container"
            >
              {!isLoading && mearchList?.data.length == 0 ? (
                <EmptyData />
              ) : (
                mearchList?.data?.map((itmes, index) => (
                  <div key={index}>
                    <div
                      style={{
                        alignSelf: "stretch",
                        flex: "1",
                        borderBottom: Colors.darkBorderColor,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "rgba(255, 255, 255, 0.5)",
                        justifyContent: "space-between",
                        padding: "1rem",
                      }}
                    >
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "20%",
                        }}
                      >
                        {" "}
                        <img src={itmes.imageUrl} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                      </div>
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "20%",
                        }}
                      >
                        {itmes.productTitle}
                      </div>
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "20%",
                        }}
                      >
                        {itmes.productPrice}
                      </div>

                      <div
                        style={{
                          width: "20%",
                        }}
                        className="fontSize16WithoutFlex"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="user_Status"
                        >
                          <button
                            onClick={() => onClickStatusChange(itmes, index)}
                            className="button"
                            style={{
                              cursor: "pointer",
                              backgroundColor: itmes.isEnabled
                                ? Colors.mainGreen01
                                : Colors.mainRed01,
                              color: itmes.isEnabled
                                ? Colors.darkGreen
                                : Colors.darkRed,
                            }}
                          >
                            {itmes.isEnabled ? "Activate" : "Deactivate"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )))}
            </div>
          </div>
        </div>
        {mearchList?.data?.length != 0 && (
          <div className="custompagination">
            <CustomPagination
              pageCount={Math.ceil(mearchList?.totalCount / 15)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={onApiCallMerchList}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default Merchandise;
