import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import Colors from "../../Constants/Colors";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import Icons from "../../Constants/Icons";
import RenderItemAchievements from "./RenderItemAchievements";
import { useNavigate } from "react-router-dom";
import {
  onApiAdminToggleAchievementStatus,
  onApiCallAdminDeleteAchievement,
  onApiCallGetAchievement,
} from "../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import EmptyData from "../../Components/EmptyData/EmptyData";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import UseDebounse from "../../Components/UseDebounse";
function Achievements() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const [searchValue, setSearchValue] = useState("");
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState("By Status");
  const [initialPage, setInitialPage] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalInActive, setTotalInActive] = useState(0);

  const [achievementsData, setAchievementsData] = useState([]);
  const [achievementsDataAll, setAchievementsDataAll] = useState([]);

  const debouncedInput = UseDebounse(searchValue, 500);
  useEffect(() => {
    onApiCallAchievementData(0);
  }, [debouncedInput]);

  const onApiCallAchievementData = async (page) => {
    dispatch(setLoadingTrue());
    let param = {
      status: null,
      searchQuery: searchValue == "" ? null : searchValue,
      offset: page + 1,
      limit: 15,
    };
    try {
      const res = await onApiCallGetAchievement(param);
      setTotalCount(res.totalCount);
      setTotalActive(res.allActiveEventType);
      setTotalInActive(res.allDeActiveEventType);
      setAchievementsData(res.data);
      setAchievementsDataAll(res.data);
      setInitialPage(page);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const handleSelect = (status) => {
    setDropdownLabelSelect(status);
    if (status === "All") {
      setAchievementsData(achievementsDataAll);
    } else {
      const filteredData = achievementsDataAll.filter((item) =>
        status === "Activate"
          ? item.isEnabled === true
          : item.isEnabled === false
      );
      setAchievementsData(filteredData);
    }
  };

  const onClickYesDelete = async (item) => {
    let param = {
      achievementId: item?.achievementId,
    };
    dispatch(setLoadingTrue());
    try {
      const res = await onApiCallAdminDeleteAchievement(param);
      console.log("resresres", res);

      const updateDeleteArray = achievementsData.filter(
        (data) => data.achievementId !== item.achievementId
      );

      if (item.isEnabled) {
        setTotalActive((prev) => prev - 1);
      } else {
        setTotalInActive((prev) => prev - 1);
      }
      setTotalCount((prev) => prev - 1);

      setAchievementsData(updateDeleteArray);
      setAchievementsDataAll(updateDeleteArray);

      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const onClickStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      achievementId: data.achievementId,
      isEnabled: !data.isEnabled,
    };
    try {
      const res = await onApiAdminToggleAchievementStatus(param);
      if (data.isEnabled) {
        setTotalActive((pre) => pre - 1);
        setTotalInActive((pre) => pre + 1);
      } else {
        setTotalActive((pre) => pre + 1);
        setTotalInActive((pre) => pre - 1);
      }
      setAchievementsData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.achievementId === data.achievementId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return updatedData;
      });
      setAchievementsDataAll((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.achievementId === data.achievementId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return updatedData;
      });
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div>
      <Header name={"Achievements"} />
      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div style={{}} className="astrologer-inner">
            <div className="frame-container">
              <div className="frame-div">
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Achievements
                  </div>
                  <div className="fontSize24">{totalCount}</div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Activate
                  </div>
                  <div
                    style={{ color: Colors.darkGreen }}
                    className="fontSize24"
                  >
                    {totalActive}
                  </div>
                </div>
                <div className="total-astrologers-parent">
                  <div
                    style={{
                      alignSelf: "stretch",
                      position: "relative",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total Deactivate
                  </div>
                  <div
                    style={{ color: Colors.darkBlue }}
                    className="fontSize24"
                  >
                    {totalInActive}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="astrologers-list-parent"
        >
          <div className="frame-parent2">
            <div className="custom-box-user">
              <div className="category_button">
                <button
                  onClick={() => {
                    navigate("/AddAchievements", {
                      state: { editAchievement: false },
                    });
                  }}
                >
                  Add Achievements
                </button>
              </div>
              <div className="custom-container-dropdown-div">
                <CustomDropdown
                  dropDownLabel={dropdownLabelSelect}
                  items={statusDropdownItem}
                  color="blue"
                  onSelect={handleSelect}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: "white",
                  }}
                />
              </div>

              <div className="custom-box-search-div">
                <img className="custom-image" alt="" src={Icons.ic_search} />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            alignSelf: "stretch",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
          }}
        >
          <div
            className="lastWeekEndDiv"
            style={{
              alignSelf: "stretch",
              position: "relative",
              fontWeight: "500",
            }}
          >
            <div
              className="user_title"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              Achievements List
            </div>

            <div
              style={{
                width: "100%",
                background: "rgba(255, 255, 255, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "1rem",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    backgroundColor: Colors.borderColor,
                    borderBottom: Colors.darkBorderColor,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "rgba(255, 255, 255, 0.5)",
                    justifyContent: "space-between",
                    padding: "1rem",
                  }}
                >
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "15%",
                    }}
                  >
                    Image
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "18%",
                    }}
                  >
                    Name
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "20%",
                    }}
                  >
                    Description
                  </div>

                  <div
                    style={{
                      width: "13%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Check In Count
                  </div>
                  <div
                    style={{
                      width: "7%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Edit
                  </div>

                  <div
                    style={{
                      width: "10%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Delete
                  </div>

                  <div
                    style={{
                      width: "10%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Status
                  </div>
                </div>
                <div
                  style={{ maxHeight: "500px" }}
                  className="scrollable-container transform_data"
                >
                  <RenderItemAchievements
                    onClickStatusChange={onClickStatusChange}
                    data={achievementsData}
                    onClickYesDelete={onClickYesDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {achievementsData.length === 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <EmptyData />
        </div>
      )}

      {achievementsData?.length !== 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              onApiCallAchievementData(selected);
            }}
          />
        </div>
      )}
    </div>
  );
}
export default Achievements;
