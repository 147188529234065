import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import Loader from "../../Components/Loader/Loader";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import "./auth.css";
import { onApiCallForgotPassword } from "../../Core/Apicall";
import { toast } from "react-toastify";

const ForgotPassword = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // State for old password
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //   navigate("/",{replace:false});
  //   }, 2000);
  // }, []);
  const [valid, setvalid] = useState(
    {
      email: true,

    }
  );
  const handleLogin = async () => {
    let isValid = true;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
      setvalid((prevState) => ({
        ...prevState,
        email: false,
      }));
    } else {
      setEmailError("");
      setvalid((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (isValid) {
      dispatch(setLoadingTrue());
      let param = {
        email: email,
      };
      try {
        const res = await onApiCallForgotPassword(param);
        navigate("/SetForgotPassword", {
          state: email,
          replace: true
        });
        setEmail("");
        setEmailError("");
        toast.success("OTP sent successfully. Please check your email", {
          autoClose: 1200,
        });
        dispatch(setLoadingFalse());
      } catch (error) {
        toast.error(error, {
          autoClose: 1200,
        });
        dispatch(setLoadingFalse());
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 7,
          marginTop: "0px",
          alignItems: "center",
        }}
      >
        <img
          src={Icons.ic_AY_logo}
          style={{ objectFit: "contain", height: "5.6rem", width: "5.6rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          background: Colors.mainDarkColor,
          borderRadius: "3px",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            padding: "10px 20px 10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="title_reset_password"
          >
            Forgot password?
          </div>
          <div className="forgot_sub_title">
            Enter the email address associated with your account and <br />{" "}
            we’ll send you a link to reset your password.
          </div>
          <div className="title_password_main">Email</div>
          <div
            className={`  ${valid.email ? 'valid' : 'invalid'}`}
            style={{
              background: "#ffffff1a",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="searchTextInput"
              placeholder="Enter email"
              style={{
                width: "100%",
              }}
            />
          </div>
          {emailError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>{emailError}</div>
          )}
          <div
            style={{
              marginTop: "20px",
              backgroundColor: "rgb(255, 69, 58)"
            }}
            onClick={() => handleLogin()}
            className="reset_password_on_click"
          >
            Send Link
          </div>
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default ForgotPassword;
