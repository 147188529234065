import React, { useEffect, useState } from "react";
import Customchekindetails from "../../../../Components/CustomuserporofilScreen/Customcheckindetails";
import Colors from "../../../../Constants/Colors";
import CheckindetailsModel from "./Checkindetailsmodel";
import { useDispatch,useSelector } from "react-redux";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import {
  apiCallDeleteEventMedia,
  apiCallUserCheckInEvent,
} from "../../../../Core/Apicall";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const Checkindetails = ({ userId }) => {
  const [eventList, setEventList] = useState([]);
  const [eventListTotalCount, setEventListTotalCount] = useState(0);
  const [checkindetailshow, setcheckindetailshow] = useState(false);
  const [checkInEventSelect, setEventCheckDataSelect] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallCheckInEvent();
  }, []);

  const onApiCallCheckInEvent = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallUserCheckInEvent(param);
      setEventList(res.data);
      setEventListTotalCount(res.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const confirmDeleted = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      eventId: checkInEventSelect.eventId,
      mediaId: data.mediaId,
    };
    try {
      const res = await apiCallDeleteEventMedia(param);
      const updatedEventData = eventList.map((event) => {
        const updatedEvent = { ...event };
        if (updatedEvent.media) {
          updatedEvent.media = updatedEvent.media.filter(
            (mediaItem) => mediaItem.mediaId !== data.mediaId
          );
        }
        return updatedEvent;
      });
      setEventList(updatedEventData);

      const updatedDetails = {
        ...checkInEventSelect,
        media: checkInEventSelect.media.filter(
          (item) => item.mediaId !== data.mediaId
        ),
      };
      setEventCheckDataSelect(updatedDetails);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };
  const hendeventmedia = (selected) => {
    setcheckindetailshow(true);
    setEventCheckDataSelect(selected);
  };

  return (
    <div className="achievements">
      <CheckindetailsModel
        checkInEventSelect={checkInEventSelect}
        checkindetailshow={checkindetailshow}
        setcheckindetailshow={setcheckindetailshow}
        confirmDeleted={confirmDeleted}
      />
      <div className="upcoming_list">
        <div
          className="margintop_20"
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div className="astrologerText">Check-in details</div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "8px",
              border: Colors.darkBorderColor,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                backgroundColor: Colors.borderColor,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "20%",
                }}
              >
                image
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "20%",
                }}
              >
                Event name
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "40%",
                }}
              >
                Event Venue
              </div>

              <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Date and Time
              </div>
              {/* <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Event Media
              </div> */}
              {/* <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                All Media
              </div> */}
            </div>

            <Customchekindetails
              checkindetails={eventList}
              hendeventmedia={hendeventmedia}
            />
          </div>
        </div>
      </div>
     {eventList.length != 0 &&  <div className="custompagination">
        <CustomPagination
          pageCount={Math.ceil(eventListTotalCount / 15)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={onApiCallCheckInEvent}
        />
      </div>
     }
    </div>
  );
};

export default Checkindetails;
