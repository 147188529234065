import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import "./auth.css";
import Header from "../../Components/Header/Header";
import { onAPiCallAdminChangePassword } from "../../Core/Apicall";
import { toast } from "react-toastify";
import { setProfileData } from "../../Config/Actions/profileActions";
const SetResetPassword = ({ }) => {
  const isProfileData = useSelector((state) => state.isProfileData.userData);

  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [Confirm, setConfirm] = useState("");
  const [showRePassword, setShowRePassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirm, setShowNewConfirm] = useState(false);
  const [errors, setErrors] = useState({});

  const [valid, setvalid] = useState(
    {
      oldPassword: true,
      newPassword: true,
      Confirm: true
    }
  );
  const dispatch = useDispatch();

  const validatePassword = (newPassword) => {
    return newPassword.length >= 8;
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;
    if (!oldPassword) {
      errors.oldPassword = "Please enter old password";
      setvalid((prevState) => ({
        ...prevState,
        oldPassword: false,
      }));
      isValid = false;
    } else {
      setvalid((prevState) => ({
        ...prevState,
        oldPassword: true,
      }));
    }

    if (!newPassword) {
      errors.newPassword = "Please enter new password";
      setvalid((prevState) => ({
        ...prevState,
        newPassword: false,
      }));
      isValid = false;
    }
    else if (newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters long";
      isValid = false;

    }
    else {
      setvalid((prevState) => ({
        ...prevState,
        newPassword: true,
      }));
    }
    if (newPassword !== Confirm) {
      errors.Confirm = "New Password and Re-enter Password do not match";
      isValid = false;
      setvalid((prevState) => ({
        ...prevState,
        Confirm: false,
      }));
    }
    else {
      setvalid((prevState) => ({
        ...prevState,
        Confirm: true,
      }));
    }
    setErrors(errors);
    return isValid;
  };

  const onClickResetPassword = async (e) => {
    if (validateForm()) {
      dispatch(setLoadingTrue());
      const param = {
        userId: isProfileData.userId,
        password: oldPassword,
        newPassword: newPassword,
      };
      try {
        const res = await onAPiCallAdminChangePassword(param);

        dispatch(setProfileData(null));
        const event = new CustomEvent("tabEventListener", {
          detail: "/Home",
        });
        window.dispatchEvent(event);
        navigate("/");
        toast.success(res.message, {
          autoClose: 1200,
        });
        setOldPassword("");
        setNewPassword("");
        setConfirm("");
        dispatch(setLoadingFalse());
      } catch (error) {
        console.log("errorerrorerror", error);
        toast.error(error, {
          autoClose: 1200,
        });

        dispatch(setLoadingFalse());
      }
    }
  };

  return (
    <div>
      <Header name="Reset Password" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 7,
            marginTop: "0px",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            marginTop: "20px",
            background: Colors.mainDarkColor,
            borderRadius: "3px",
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              padding: "10px 20px 10px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{ textAlign: "center", marginBottom: "10px" }}
              className="title_reset_password"
            >
              Reset Password
            </div>
            <div style={{}}>
              <div className="title_password_main">Old password</div>
              <div className={`  ${valid.oldPassword ? 'valid' : 'invalid'}`}
                style={{
                  background: "#ffffff1a",
                  borderRadius: "3px",
                  width: "45vh",
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <input
                  type={showRePassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="searchTextInput"
                  placeholder="Enter old password"
                  style={{
                    width: "100%", // Ensure the input fills the parent div
                  }}
                />
                <img
                  onClick={() => setShowRePassword(!showRePassword)}
                  src={showRePassword ? Icons.hidde : Icons.show}
                  style={{
                    cursor: "pointer",
                    height: "0.8rem",
                    width: "1rem",
                  }}
                />
              </div>

              {errors.oldPassword && (
                <div style={{ fontSize: "0.77rem", color: "red" }}>
                  {errors.oldPassword}
                </div>
              )}

              <div className="title_password_main">New password</div>
              <div
                className={`${valid.newPassword ? 'valid' : 'invalid'}`}
                style={{
                  background: "#ffffff1a",
                  borderRadius: "3px",
                  width: "45vh",
                  display: "flex",
                  padding: 10,
                  alignItems: "center",
                }}
              >
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="searchTextInput"
                  placeholder="Enter new password"
                  style={{
                    width: "100%", // Ensure the input fills the parent div
                  }}
                />
                <img
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  src={showNewPassword ? Icons.hidde : Icons.show}
                  style={{
                    cursor: "pointer",
                    height: "0.8rem",
                    width: "1rem",
                  }}
                />
              </div>
              {errors.newPassword && (
                <div style={{ fontSize: "0.77rem", color: "red" }}>
                  {errors.newPassword}
                </div>
              )}
              <div className="title_password_main">Re-enter new password</div>
              <div
                className={`${valid.Confirm ? 'valid' : 'invalid'}`}
                style={{
                  background: "#ffffff1a",
                  borderRadius: "3px",
                  width: "45vh",
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <input
                  type={showNewConfirm ? "text" : "password"}
                  value={Confirm}
                  onChange={(e) => setConfirm(e.target.value)}
                  className="searchTextInput"
                  placeholder="Enter Re-enter new password"
                  style={{
                    width: "100%", // Ensure the input fills the parent div
                  }}
                />
                <img
                  onClick={() => setShowNewConfirm(!showNewConfirm)}
                  src={showNewConfirm ? Icons.hidde : Icons.show}
                  style={{
                    cursor: "pointer",
                    height: "0.8rem",
                    width: "1rem",
                  }}
                />
              </div>
              {errors.Confirm && (
                <div style={{ fontSize: "0.77rem", color: "red" }}>
                  {errors.Confirm}
                </div>
              )}
              <div
                style={{
                  marginTop: "20px",
                  width: "150px",
                  backgroundColor: "rgb(255, 69, 58)"
                }}
                onClick={() => onClickResetPassword()}
                className="reset_password_on_click"
              >
                Set new password
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetResetPassword;
