import React, { useEffect, useState } from "react";
import "../../../../../../src/Components/DropDown/CustomDropDownStyle.css";
import Icons from "../../../../../Constants/Icons";
import { apiCall_getAdminEventTypes } from "../../../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../../Config/Actions/loadingActions";

function CustomDropdown({ onSelect, dropDownLabel, customLabelStyle ,setStatesForPersonal}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState( );
  const [items, setArtType] = useState([]);
  const [cur_section, setCurSection] = useState("");

  useEffect(() => {
    onApiEventTypeList(); 
    // const hasPersonalEvent = items.some(item => item.eventTypeName === "Personal");
    // if (hasPersonalEvent) {
    //   setStatesForPersonal(); 
    // }
    
  }, []);
  

  const dispatch = useDispatch();
  const onApiEventTypeList = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const response = await apiCall_getAdminEventTypes(param);
      setArtType(response.data || []);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Error fetching event types:", error);
      dispatch(setLoadingFalse());
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item, event) => {
    setSelectedItem(item);
    setIsOpen(false);
    setCurSection(item);
    onSelect(item);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div onMouseLeave={handleMouseLeave} className="custom-dropdown">
      <div
        onClick={toggleDropdown}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 26,
        }}
      >
        <div style={customLabelStyle} className="custom-dropdown2">
          {dropDownLabel}
        </div>
        <div style={{ display: "flex" }}>
          <img className="downArrowIcon" alt="" src={Icons.ic_down} />
        </div>
      </div>
      {isOpen && (
        <div style={{ position: "absolute", width: "100%", left: "0px" }}>
          <div style={{ height: 10 }}></div>
          <ul className="dropdown-menu">
            {items.map((item) => (
              <li
                className={item.eventTypeName === dropDownLabel ? "active" : ""}
                key={item.eventTypeId}
                onClick={(event) => handleItemClick(item, event)}
              >
                {item.eventTypeName}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
