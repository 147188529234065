import React, { useEffect } from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import { useState } from "react";
import Artprojectsmodel from "./ArtprojectsModal";
import {
  apiCallGetUserArtProject,
  apiCallPostToggleArtProjectStatus,
} from "../../../../Core/Apicall";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const Artprojects = ({ userId }) => {
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [artProjectData, setArtProjectData] = useState([]);
  const [Artshow, setArtshow] = useState(false);
  const [mediaSelectData, setSelectMedia] = useState();

  useEffect(() => {
    apiCallGetArtProjectData();
  }, []);

  const onClickStatusChange = async (item) => {
    dispatch(setLoadingTrue());
    const param = {
      videoId: item.videoId,
      isEnabled: !item.isEnabled,
    };
    try {
      await apiCallPostToggleArtProjectStatus(param);
      apiCallGetArtProjectData();
      // dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Error fetching event types:", error);
      dispatch(setLoadingFalse());
    }
  };

  const onClickMediaOpen = async (item) => {
    setArtshow(true);
    setSelectMedia(item);
  };

  const apiCallGetArtProjectData = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetUserArtProject(param);
      setArtProjectData(res.data);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Error fetching event types:", error);
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div>
      {Artshow && (
        <Artprojectsmodel
          Artshow={Artshow}
          mediaSelectData={mediaSelectData}
          setArtshow={setArtshow}
        />
      )}
      <div className="achievements">
        <div className="upcoming_list">
          <div
            className="margintop_20"
            style={{
              width: "100%",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              gap: "1rem",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div className="astrologerText">Art Projects</div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  backgroundColor: Colors.borderColor,
                  borderBottom: Colors.darkBorderColor,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.5)",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "15%",
                  }}
                >
                  Title
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "35%",
                  }}
                >
                  Description
                </div>
                <div
                  style={{
                    width: "15%",
                  }}
                  className="lastWeekEndDivGreyColor"
                >
                  Media
                </div>
                <div
                  style={{
                    width: "15%",
                  }}
                  className="lastWeekEndDivGreyColor"
                >
                  Status
                </div>
              </div>
              <div
                style={{ maxHeight: "350px" }}
                className="scrollable-container"
              >
                {!isLoading && artProjectData.length == 0 ? (
                  <EmptyData />
                ) : (
                  artProjectData.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{
                          alignSelf: "stretch",
                          flex: "1",
                          borderBottom: Colors.darkBorderColor,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color: "rgba(255, 255, 255, 0.5)",
                          justifyContent: "space-between",
                          padding: "1rem",
                        }}
                      >
                        <div
                          className="fontSize16WithoutFlex"
                          style={{
                            width: "15%",
                          }}
                        >
                          {item.title}
                        </div>
                        <div
                          className="fontSize16WithoutFlex"
                          style={{
                            width: "35%",
                          }}
                        >
                          {item.description}
                        </div>
                        <div
                          className="fontSize16WithoutFlex"
                          style={{
                            width: "15%",
                          }}
                        >
                          <img
                            src={Icons.all_m}
                            onClick={() => onClickMediaOpen(item)}
                            style={{
                              height: "35px",
                              filter: "brightness(0) invert(1)",
                              cursor: "pointer",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            width: "15%",
                          }}
                          className="fontSize16WithoutFlex"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="user_Status"
                          >
                            <button
                              onClick={() => onClickStatusChange(item)}
                              className="button"
                              style={{
                                cursor: "pointer",
                                backgroundColor: item.isEnabled
                                  ? Colors.mainGreen01
                                  : Colors.mainRed01,
                                color: item.isEnabled
                                  ? Colors.darkGreen
                                  : Colors.darkRed,
                              }}
                            >
                              {item.isEnabled ? "Activate" : "Deactivate"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artprojects;
