import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import "./auth.css";
import Loader from "../../Components/Loader/Loader";
import { onAPiCallUserResetPassword } from "../../Core/Apicall";
import { toast } from "react-toastify";

const SetForgotPassword = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [Confirm, setConfirm] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [showNewConfirm, setShowNewConfirm] = useState(false);
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();

  const validatePassword = (newPassword) => {
    return newPassword.length >= 8;
  };

  const handleOnClick = async (e) => {
    let isValid = true;
    if (otp == "") {
      setOtpError("Please enter otp");
      isValid = false;
    } else {
      setOtpError("");
    }

    if (!validatePassword(newPassword)) {
      setPasswordError("Password must be at least 8 characters long");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (newPassword != Confirm) {
      setRePasswordError("New Password and Re-enter Password do not match");
      isValid = false;
    } else {
      setRePasswordError("");
    }

    if (isValid) {
      let param = {
        email: location?.state,
        otp: otp,
        newPassword: newPassword,
      };
      try {
        dispatch(setLoadingTrue());
        let res = await onAPiCallUserResetPassword(param);
        toast.success(res.message, {
          autoClose: 1200,
        });
        setOtp("");
        setNewPassword("");
        setConfirm("");
        navigate("/", { replace: true });
        dispatch(setLoadingFalse());
      } catch (error) {
        toast.error(error, {
          autoClose: 1200,
        });
        dispatch(setLoadingFalse());
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 7,
          marginTop: "0px",
          alignItems: "center",
        }}
      >
        <img
          src={Icons.ic_AY_logo}
          style={{ objectFit: "contain", height: "5.6rem", width: "5.6rem" }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          background: Colors.mainDarkColor,

          borderRadius: "3px",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            padding: "10px 20px 10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="title_reset_password"
          >
            Set Forgot Password
          </div>

          <div className="title_password_main">Enter otp</div>
          <div
            style={{
              border: "1px solid #0A84FF",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              value={otp}
              autoComplete="off"
              maxLength={8}
              name="otp"
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
              className="searchTextInput"
              placeholder="Enter Otp"
              style={{
                width: "100%",
              }}
            />
          </div>
          {otpError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>{otpError}</div>
          )}

          <div className="title_password_main">Enter password</div>
          <div
            style={{
              border: "1px solid #0A84FF",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              autocomplete="whatever"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="searchTextInput"
              placeholder="Enter password"
              style={{
                width: "100%",
              }}
            />
            <img
              onClick={() => setShowNewPassword(!showNewPassword)}
              src={showNewPassword ? Icons.hidde : Icons.show}
              style={{ cursor: "pointer", height: "0.8rem", width: "1rem" }}
            />
          </div>
          {passwordError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>
              {passwordError}
            </div>
          )}

          <div className="title_password_main">Re-enter new password</div>
          <div
            style={{
              border: "1px solid #0A84FF",
              borderRadius: "3px",
              width: "45vh",
              display: "flex",
              padding: "6px 15px",
            }}
          >
            <input
              autocomplete="new-password"
              name="devicePassword"
              type={showNewConfirm ? "text" : "password"}
              value={Confirm}
              onChange={(e) => setConfirm(e.target.value)}
              className="searchTextInput "
              placeholder="Enter password"
              style={{
                width: "100%",
              }}
            />
            <img
              onClick={() => setShowNewConfirm(!showNewConfirm)}
              src={showNewConfirm ? Icons.hidde : Icons.show}
              style={{
                cursor: "pointer",
                height: "0.8rem",
                width: "1rem",
              }}
            />
          </div>
          {rePasswordError && (
            <div style={{ color: "red", fontSize: "0.8rem" }}>
              {rePasswordError}
            </div>
          )}

          <div
            style={{
              marginTop: "20px",
              width: "150px",
            }}
            onClick={() => handleOnClick()}
            className="reset_password_on_click ______++_++"
          >
            Set Forgot Password
          </div>
        </div>
        <Loader />
      </div>
    </div>
  );
};

export default SetForgotPassword;
