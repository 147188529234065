import React, { useState } from 'react';
import Colors from '../../Constants/Colors';
import Icons from '../../Constants/Icons';
import { useSelector } from 'react-redux';
import EmptyData from '../EmptyData/EmptyData';
const Customchekindetails = ({checkindetails,hendeventmedia}) => {
    const isLoading = useSelector((state) => state.isLoading);

    const hendEnabled =() =>{

    }
    return (       
            <div
                style={{ maxHeight: "350px" }}
                className="scrollable-container"
            >
             {!isLoading && checkindetails.length == 0 ? (
                <EmptyData />
              ) : (
                checkindetails?.map((item, index) => (
                    <div key={index}>
                        <div
                            style={{
                                alignSelf: "stretch",
                                flex: "1",
                                borderBottom: Colors.darkBorderColor,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                color: "rgba(255, 255, 255, 0.5)",
                                justifyContent: "space-between",
                                padding: "1rem",
                            }}
                        >
                            <div
                                className="lastWeekEndDivGreyColor"
                                style={{
                                    width: "20%",
                                }}
                            >
                                <img src={item.eventImages} style={{ width: "50px" ,height:"50px"}} />
                            </div>
                            <div
                                className="fontSize16WithoutFlex"
                                style={{
                                    width: "20%",
                                }}
                            >
                                {item.eventName}
                            </div>
                            <div
                                className="fontSize16WithoutFlex"
                                style={{
                                    width: "40%",
                                }}
                            >
                                {item.venueAddress}

                            </div>
                           
                            <div
                                style={{
                                    width: "20%",
                                }}
                                className="fontSize16WithoutFlex"
                            >
                                {item.eventStartDate}
                            </div>
                            {/* <div
                                style={{
                                    width: "20%",
                                }}
                                className="fontSize16WithoutFlex"
                            >
                               <img onClick={()=>hendeventmedia(item)} src={Icons.event_m}  style={{height:"30px",filter: "brightness(0) invert(1)",cursor:"pointer"}}  />
                            </div> */}
                        </div>

                    </div>)))}
            </div>
      
    )
}
export default Customchekindetails;