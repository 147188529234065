import axios from "axios";
import { BaseApiUrl } from "./Until";
import { store } from "../Config/store";
import { setLoadingFalse } from "../Config/Actions/loadingActions";
import { setProfileData } from "../Config/Actions/profileActions";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: BaseApiUrl,
});
instance.defaults.headers.post["Content-Type"] = "application/json";
let toastShown = false;
instance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    if (state && state?.isProfileData && state?.isProfileData?.token) {
      config.headers.Authorization = `Bearer ${state?.isProfileData?.token}`;
    }
    return config;
  },
  function (error) {
    store.dispatch(setLoadingFalse(true));
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error", error);
    if (error.response.data.message === "Token expired") {
      handleLogout();
    }
    return Promise.reject(error);
  }
);
const handleLogout = () => {
  if (!toastShown) {
    toast.error("Your session has expired. Please log in again to continue.", {
      autoClose: 2000,
    });
    toastShown = true;
  }
  store.dispatch(setProfileData(null));
};

export default instance;
