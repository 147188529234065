import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header/Header";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import CustomUserScreen from "../../Components/CustomUserScreen/CustomUserScreen";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import {
  apiCallGetUser,
  apiCallToggleUserStatus,
  onAPiCallAdminToggleFeaturedUser,
  onAPiCallUsersearchUser,
} from "../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import EmptyData from "../../Components/EmptyData/EmptyData";
import UseDebounse from "../../Components/UseDebounse";

function UserManagement() {
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const dispatch = useDispatch();
  const [userListData, setUserListData] = useState();
  const isLoading = useSelector((state) => state.isLoading);

  const [userTotalActive, setUserTotalActive] = useState(0);
  const [userTotalInActive, setUserTotalInActive] = useState(0);
  const [userTotalCount, setUserTotalCount] = useState(0);

  const [userListAllData, setUserListAllData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [dropdownLabelSelect, setDropdownLabelSelect] = useState("By Status");
  const [initialPage, setInitialPage] = useState(0);

  const handleSelect = (status) => {
    setDropdownLabelSelect(status);
    // setSearchValue("");
    if (status === "All") {
      setUserListData(userListAllData);
    } else {
      const filteredData = userListAllData.data.filter((item) =>
        status === "Activate"
          ? item.isEnabled === true
          : item.isEnabled === false
      );
      setUserListData({ ...userListData, data: filteredData });
    }
  };
  const debouncedInput = UseDebounse(searchValue, 500);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    searchFilterFunction(searchValue, 0);
  }, [debouncedInput]);
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const searchFilterFunction = async (text, page) => {
    setDropdownLabelSelect("By Status");
    const param = {
      searchQuery: text,
      offset: page + 1,
      limit: 15,
    };
    dispatch(setLoadingTrue());
    try {
      const res = await onAPiCallUsersearchUser(param);
      console.log("resresresresresresres", res);
      setInitialPage(page);
      setUserListData(res);
      setUserListAllData(res);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  useEffect(() => {
    onApiCallUserList(0);
  }, []);

  const onApiCallUserList = async (selectedPage) => {
    console.log("selectedPage", selectedPage);

    dispatch(setLoadingTrue());
    const param = {
      status: null,
      offset: selectedPage + 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetUser(param);
      console.log("resresres",res);
      
      setUserTotalActive(res?.allActiveUser);
      setUserTotalCount(res?.allUser);
      setUserTotalInActive(res?.allDeActiveUser);
      setDropdownLabelSelect("By Status");
      setInitialPage(selectedPage ?? 0);
      setUserListData(res);
      setUserListAllData(res);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const onClickFeatureStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: data.userId,
      isFeatured: !data.isFeatured,
    };
    try {
      await onAPiCallAdminToggleFeaturedUser(param);
      setUserListData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.userId === data.userId) {
            return { ...item, isFeatured: !item.isFeatured };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      setUserListAllData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.userId === data.userId) {
            return { ...item, isFeatured: !item.isFeatured };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
    } catch (error) {
      console.error("Error toggling featured status:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const onClickStatusChange = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: data.userId,
      isEnabled: !data.isEnabled,
    };
    try {
      await apiCallToggleUserStatus(param);
      if (data.isEnabled) {
        setUserTotalActive((prev) => prev - 1);
        setUserTotalInActive((prev) => prev + 1);
      } else {
        setUserTotalActive((prev) => prev + 1);
        setUserTotalInActive((prev) => prev - 1);
      }
      setUserListData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.userId === data.userId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
      setUserListAllData((prevData) => {
        const updatedData = prevData.data.map((item) => {
          if (item.userId === data.userId) {
            return { ...item, isEnabled: !item.isEnabled };
          }
          return item;
        });
        return { ...prevData, data: updatedData };
      });
    } catch (error) {
      console.error("Error toggling user status:", error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div>
      <Header name={"User Management"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            {/* <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div> */}
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                  className="lastWeekEndDiv"
                >
                  Total Users
                </div>
                <div className="fontSize24">{userTotalCount}</div>
              </div>
              <div className="total-astrologers-parent">
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                  className="lastWeekEndDiv"
                >
                  Total Activate
                </div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {userTotalActive}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                  }}
                  className="lastWeekEndDiv"
                >
                  Total Deactivate
                </div>
                <div style={{ color: Colors.darkBlue }} className="fontSize24">
                  {userTotalInActive}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={dropdownLabelSelect}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelect}
                customLabelStyle={{ fontWeight: "400", color: "white" }}
              />
            </div>

            <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                disabled={isLoading}
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
        }}
      >
        <div
          className="lastWeekEndDiv"
          style={{
            alignSelf: "stretch",
            position: "relative",
            fontWeight: "500",
          }}
        >
          <div className="user_title">User list</div>
          <div style={{ background: "rgba(255, 255, 255, 0.1)" }}>
            <nav
              className="grid_colum grid_7 fontSize16WithoutFlex"
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                padding: "20px",
              }}
            >
              <div className="user_name">Name</div>
              <div className="user_name">Username</div>
              <div className="user_name">Contact</div>
              <div className="user_name">Date of birth</div>
              <div className="user_name">User type</div>
              <div className="user_name" style={{ width: "10%" }}>
                Edit
              </div>
              <div className="user_name">Status</div>
              <div className="user_name">Featured</div>
            </nav>
            <CustomUserScreen
              currentUsers={userListData?.data}
              onClickStatusChange={onClickStatusChange}
              onClickFeatureStatusChange={onClickFeatureStatusChange}
            />
          </div>
        </div>
      </div>

      {userListData?.data.length === 0 && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <EmptyData />
        </div>
      )}

      {userListData?.data.length !== 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(userListData?.totalCount / 15)}
            pageRangeDisplayed={3}
            initialPage={initialPage}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => {
              if (searchValue) {
                searchFilterFunction(searchValue, selected);
              } else {
                onApiCallUserList(selected);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default UserManagement;
