// apiService.js

export const CustomUploadProfilePic = (formData) => {

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch("http://194.163.176.34:5400/api/v1/users/uploadImage", requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error('There was an error uploading the profile picture: ' + error.message);
    });
};
