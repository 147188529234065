  import instance from "./ApiConfig";

  export const baseApiCall = (config) => {
    return new Promise((resolve, reject) => {
      instance(config)
        .then((response) => {
          if (response?.status === 200) {
            resolve(response?.data);
          } 
        })
        .catch((e) => {
          reject(e.response.data.message);
        });
    });
  };
