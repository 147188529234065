import React, { useEffect, useRef } from "react";
import Icons from "../../Constants/Icons";
import { Link, useNavigate } from "react-router-dom";
import Colors from "../../Constants/Colors";

const UserListItem = ({
  currentUsers,
  onClickStatusChange,
  onClickFeatureStatusChange,
}) => {
  const navigate = useNavigate();
  const handleEditClick = (currentUsers) => {
    navigate("/UserUpdate", {
      state: {
        userId: currentUsers.userId,
        userId: currentUsers.userId,
      },
    });
  };
  return (
    <div>
      <div
        style={{ maxHeight: "500px" }}
        className="scrollable-container transform_data"
      >
        {currentUsers?.map((currentUsers, index) => (
          <div key={index} className="transaction-item cursor-pointer">
            <div
              className="list-user-div-container border-bottom"
              style={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "1px",
              }}
            >
              <div className="grid_colum grid_7" style={{ padding: "20px" }}>
                <div
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: currentUsers.userId,
                      },
                    })
                  }
                  className="fontSize16WithoutFlex  user_name"
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={currentUsers.image ?? Icons.ic_default_user}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "25px",
                    }}
                  />
                  <div
                    className="user_text"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/UserProfile", {
                        state: {
                          userId: currentUsers.userId,
                        },
                      })
                    }
                  >
                    {currentUsers.name}
                  </div>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: currentUsers.userId,
                      },
                    })
                  }
                  className="fontSize16WithoutFlex user_name"
                >
                  {currentUsers.userName}
                </div>
                <div
                  style={{ display: "block", gap: "4px" }}
                  className="user_name"
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    onClick={() =>
                      navigate("/UserProfile", {
                        state: {
                          userId: currentUsers.userId,
                        },
                      })
                    }
                    className="fontSize16WithoutFlex"
                  >
                    {" "}
                    <img
                      style={{
                        width: "1rem",
                        objectFit: "fill",
                        height: "1rem",
                        display: "inline-block",
                        marginRight: "2px",
                      }}
                      src={Icons.ic_sms}
                      alt="new"
                    />
                    <div className="user_text">{currentUsers.userEmail}</div>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      marginTop: "4px",
                    }}
                    onClick={() =>
                      navigate("/UserProfile", {
                        state: {
                          userId: currentUsers.userId,
                        },
                      })
                    }
                    className="fontSize16WithoutFlex"
                  >
                    {" "}
                    <img
                      className="icon_style_list "
                      alt=""
                      src={Icons.ic_call}
                    />
                    <div className="user_text">{currentUsers.mobile}</div>
                  </div>
                </div>
                {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: currentUsers.userId,
                      },
                    })
                  }
                  className="fontSize16WithoutFlex user_name"
                >
                  {currentUsers.Interestcategory}
                </div> */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: currentUsers.userId,
                      },
                    })
                  }
                  className="fontSize16WithoutFlex user_name"
                >
                  {currentUsers.birthDate}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/UserProfile", {
                      state: {
                        userId: currentUsers.userId,
                      },
                    })
                  }
                  className="fontSize16WithoutFlex user_name"
                >
                  {currentUsers.userType}
                </div>
                <div style={{ width: "10%" }}>
                  <img
                    src={Icons.edit}
                    onClick={() => handleEditClick(currentUsers)}
                    style={{
                      width: "20px",
                      cursor: "pointer",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  className="user_Status user_name"
                >
                  <button
                    onClick={() => onClickStatusChange(currentUsers)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: currentUsers.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: currentUsers.isEnabled
                        ? Colors.darkGreen
                        : Colors.darkRed,
                    }}
                  >
                    {currentUsers.isEnabled ? "Activate" : "Deactivate"}
                  </button>
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  className="user_Status user_name"
                >
                  <button
                    onClick={() => onClickFeatureStatusChange(currentUsers)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: currentUsers.isFeatured
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: currentUsers.isFeatured
                        ? Colors.darkGreen
                        : Colors.darkRed,
                    }}
                  >
                    {currentUsers.isFeatured ? "Activate" : "Deactivate"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserListItem;
