import React from "react";
import Icons from "../../../../Constants/Icons";
import { useState, useRef } from "react";

const Checkindetailsmodel = ({
  checkindetailshow,
  checkInEventSelect,
  setcheckindetailshow,
  confirmDeleted,
}) => {
  const handleClick = () => {
    setcheckindetailshow(false);
  };

  const handleDeleted = (item) => {
    if (window.confirm("Are you sure you want to delete this media?")) {
      confirmDeleted(item);
    }
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const videoRefs = useRef({});
  const handlePlay = (id) => {
    const videoRef = videoRefs.current[id];
    if (videoRef) {
      videoRef.play();
      // setIsPlaying(true);
      setIsPlaying((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      setCurrentVideoId(id);
      console.log("Video play id:", id);
    }
  };

  const handlePause = (id) => {
    const videoRef = videoRefs.current[id];
    if (videoRef) {
      videoRef.pause();
      setIsPlaying((prevState) => ({
        ...prevState,
        [id]: false,
      }));
      setCurrentVideoId(id);
      console.log("Video pause id:", id);
    }
  };
  return (
    <div
      className={`modalcategory ${
        checkindetailshow ? "display-block" : "display-none"
      }`}
    >
      <div
      
        className="modal-content Checkindetailsmodel"
        style={{ maxHeight: "100vh",overflow: "auto" }}
      >
        <span className="close" onClick={handleClick}>
          &times;
        </span>
        <div
          className={`showe ${
            checkindetailshow ? "display-block" : "display-none"
          }`}
        >
          <div
            style={{
              textAlign: "left",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "rgba(255, 255, 255, 0.9)",
              padding: "20px 10px",
            }}
            className="lastWeekEndDivGreyColor"
          >
            Event Media
          </div>
          <div style={{display:"flex",flexDirection:"row"}} className="show">
            {checkInEventSelect?.media?.length != 0 ? (
              checkInEventSelect?.media?.map((item) => (
                <>
                <div style={{}}>
                  {item?.mediaUrl?.slice(item?.mediaUrl?.length - 4) !=
                  ".mp4" ? (
                    <div
                      key={item.mediaId}
                      className="media_menu"
                      style={{ borderRadius: "10px", overflow: "hidden", }}
                    >
                      <img
                        src={item.mediaUrl}
                        alt="User Media"
                        style={{
                          height: "100px",
                          width: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <img
                        className="delete_media"
                        src={Icons.delete}
                        onClick={() => handleDeleted(item)}
                        style={{
                          width: "20px",
                          cursor: "pointer",
                          filter: "brightness(0) invert(1)",
                        }}
                        alt="Delete Icon"
                      />
                    </div>
                  ) : (
                    <div
                      key={item.mediaId}
                      className="media_menu"
                      style={{
                        marginBottom: "0px",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <video
                        src={item.mediaUrl}
                        loop
                        ref={(el) => (videoRefs.current[item.mediaId] = el)}
                        style={{
                          height: "100px",
                          width: "200px",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                      />
                      <img
                        className="delete_media"
                        src={Icons.delete}
                        onClick={() => handleDeleted(item)}
                        style={{
                          width: "20px",
                          cursor: "pointer",
                          filter: "brightness(0) invert(1)",
                        }}
                        alt="Delete Icon"
                      />
                      {!isPlaying[item.mediaId] && (
                        <div
                          onClick={() => handlePlay(item.mediaId)}
                          style={{ position: "absolute", cursor: "pointer" }}
                        >
                          <img
                            src={Icons.playbutton}
                            style={{ height: "35px" }}
                          />
                        </div>
                      )}
                      {isPlaying[item.mediaId] && (
                        <div
                          onClick={() => handlePause(item.mediaId)}
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                          }}
                        ></div>
                      )}
                    </div>
                  )}
                  </div>
                </>
              ))
            ) : (
              <p style={{ color: "white", textAlign: "center" }}>
                No media available
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkindetailsmodel;
