import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./Screens/Authfrom/Login";
import Colors from "./Constants/Colors";
import ForgotPassword from "./Screens/Authfrom/ForgotPassword";
import SetResetPassword from "./Screens/Authfrom/SetResetPassword";
import Icons from "./Constants/Icons";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Loader from "./Components/Loader/Loader";
import Box from "@mui/material/Box";
import { Collapse } from "@mui/material";
import Dashboard from "./Screens/Dashboard/Dashboard";
import CategoryManagementd from "./Screens/CategoryManagement/Category";
import UserManagement from "./Screens/UserManagement/UserManagement";
import UserProfile from "./Screens/UserManagement/UserProfile/UserProfile";
import EventsManagement from "./Screens/EventsManagement/EventsManagement";
import Eventdetails from "./Screens/EventsManagement/Eventdetails/Eventdetails";
import EventUpdate from "./Screens/UserManagement/UserTab/Userlist/EventUpdate/EventUpdate";
import Subcategory from "./Screens/CategoryManagement/Subcategory/Subcategory";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PastEvent from "./Screens/EventsManagement/PastEvent";
import UpcomingEvent from "./Screens/EventsManagement/UpcomingEvent";
import FeaturedEvent from "./Screens/EventsManagement/FeaturedEvent";
import TodayEvents from "./Screens/EventsManagement/TodayEvents";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SetForgotPassword from "./Screens/Authfrom/SetForgotPassword";
import UserUpdate from "./Screens/UserManagement/UserTab/Userlist/UserUpdate/UserUpdate";
import WithOutCheckInEvent  from "./Screens/EventsManagement/WithOutCheckInEvent";
import Achievements from "./Screens/Achievement/Achievements";
import AddAchievements from "./Screens/Achievement/AddAchievements";

const ListScreenData = [
  {
    text: "Dashboard",
    icon: Icons.ic_grey_home,
    icongWhite: Icons.ic_home,
    route: "/",
  },
  // {
  //   text: "Event Type",
  //   icon: Icons.ic_type,
  //   icongWhite: Icons.ic_typewhite,
  //   route: "/EventType",
  // },
  {
    text: "Event Category",
    icon: Icons.Categorie,
    icongWhite: Icons.Categorie1,
    route: "/Category",
  },
  {
    text: "User",
    icon: Icons.ic_user,
    icongWhite: Icons.ic_user_white,
    route: "/UserManagement",
  },
  {
    text: "Achievements",
    icon: Icons.ic_grey_achievement,
    icongWhite: Icons.ic_white_achievement,
    route: "/Achievements",
  },
  {
    text: "Events",
    icon: Icons.eventm,
    route: "/EventsManagement",
    icongWhite: Icons.event,
    arrowIcon: Icons.ic_down,
    subItems: [
      {
        text: "Today Event",
        route: "/TodayEvents",
      },

      {
        text: "Upcoming Event",
        route: "/UpcomingEvent",
      },
      {
        text: "Past Event",
        route: "/PastEvent",
      },
      {
        text: "Featured Event",
        route: "/FeaturedEvent",
      },
      {
        text: "Admin Event",
        route: "/WithOutCheckInEvent",
      }
    ],
  },
  // {
  //   text: "Logout",
  //   icon: Icons.power,
  //   route: "/",
  //   icongWhite: Icons.powerwhite,
  // },
];
function App() {
  const isProfileData = useSelector((state) => state.isProfileData);
  const [selectedItem, setSelectedItem] = useState("/");
  const [selectedSubItem, setSelectedSubItem] = useState("");
  const [openSubItems, setOpenSubItems] = useState("");
  const [subItemShow, setSubItemShow] = useState(null);
  const [forgotNavigate, setForgotNavigate] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    isProfileData == null ? false : true
  );


  const location = useLocation()
  useEffect(() => {
    const currentPath = location.pathname;
    const matchedItem = ListScreenData.find((item) => item.route === currentPath);
    if (matchedItem) {
      setSelectedItem(matchedItem.route);
      if (matchedItem.subItems) {
        setSubItemShow(true);
        setOpenSubItems(matchedItem.route);
      } else {
        setSubItemShow(false);
        setOpenSubItems("");
      }
    } else {
      const matchedSubItem = ListScreenData.flatMap(item => item.subItems || [])
        .find(subItem => subItem.route === currentPath);
      if (matchedSubItem) {
        setSelectedSubItem(matchedSubItem.route);
        const parentItem = ListScreenData.find(item => item.subItems && item.subItems.includes(matchedSubItem));
        if (parentItem) {
          setSelectedItem(parentItem.route);
          setOpenSubItems(parentItem.route);
          setSubItemShow(true);
        }
      }
    }
  }, [location.pathname]);



  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      setIsLoggedIn(JSON.parse(isLoggedIn));
    }
  }, [isLoggedIn]);

  const handleItemClick = (item) => {
    setSelectedSubItem("");
    setSelectedItem(item.route || item);
    setOpenSubItems(item.route || item);
    if(item.text === "User" ||item.text ==="Event Category"|| item.text ==="Dashboard"){
      setSubItemShow(false)
    }
  };

  const handleSubItemClick = (subItem) => {
    setSelectedSubItem(subItem.route);
  };

  const isSubItemOpen = (route) => {
    return openSubItems === route;
  };
  useEffect(() => {
    const eventListener = (event) => {
      const navLink = event.detail;
      handleItemClick(navLink);
    };
    window.addEventListener("tabEventListener", eventListener);
    return () => {
      window.removeEventListener("tabEventListener", eventListener);
    };
  }, []);

  useEffect(() => {
    const eventListener = (event) => {
      const navLink = event.detail;
      setSelectedItem("/EventsManagement");
      setOpenSubItems("/EventsManagement");
      setSubItemShow(true);
      setSelectedSubItem(navLink);
    };
    window.addEventListener("subTabEventListner", eventListener);
    return () => {
      window.removeEventListener("subTabEventListner", eventListener);
    };
  }, []);
  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      const currentPathGet = ListScreenData.filter(
        (item) => item.route === currentPath
      );

      if (currentPathGet.length != 0) {
        handleItemClick(currentPathGet[0].route);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => { }, []);
  const drawerWidth = "15%";
  return (
    <div style={{ background: "rgba(6, 101, 228, 0.2)", minHeight: "100vh" }}>
      {/* <Router> */}
        {isProfileData ? (
          <div style={{ color: "white" }}>
            <Box sx={{ display: "flex" }}>
              <Drawer
                variant="permanent"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    background: "rgba(6, 101, 228, 0.2)",
                  },
                }}
              >
                {" "}
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      padding: 10,
                      maxWidth: "220px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      gap: 10,
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        width: "2.5rem",
                        objectFit: "contain",
                        height: "2.5rem",
                        borderRadius: "2.5rem",
                      }}
                      src={Icons.ic_AY_logo}
                      alt="new"
                    />
                    <div
                      style={{
                        fontFamily: "Poppins-regular",
                        fontSize: "1.2rem",
                        fontWeight: "800",
                        color: Colors.white,
                      }}
                    >
                      Artfulyo
                    </div>
                  </div>
                </Link>
                <List
                  sx={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#6b6b6b",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {ListScreenData.map((item, index) => {
                    const isSelected = selectedItem === item.route;
                    return (
                      <div key={item.text}>
                        <ListItem sx={{}}>
                          <ListItemButton
                            className={`listItemButton
                         ${isSelected ? "active" : ""} no-hover`}
                            sx={{
                              color: "#818181",
                              padding: "0.4rem",
                            }}
                            component={NavLink}
                            to={item.route}
                            onClick={() => handleItemClick(item)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                                gap: 6,
                              }}
                            >
                              <img
                                style={{
                                  width: "1.2rem",
                                  objectFit: "contain",
                                  height: "1.2rem",
                                }}
                                src={isSelected ? item.icongWhite : item.icon}
                                alt="new"
                              />
                              <div style={{ fontSize: "0.9rem" }}>
                                {item.text}
                              </div>
                              {item.arrowIcon && (
                                <img
                                  className={` ${subItemShow ? 'validmenuround' : 'menuround'}`}
                                  onClick={() => (
                                    setSubItemShow(!subItemShow)

                                  )}
                                  style={{
                                    position: "absolute",
                                    right: "1rem",
                                    display: "flex",
                                    width: "1.2rem",
                                    objectFit: "contain",
                                    height: "1.2rem",
                                  }}
                                  src={item.arrowIcon}
                                  alt="new"
                                />
                              )}
                            </div>
                          </ListItemButton>
                        </ListItem>
                        {subItemShow && (
                          <Collapse in={isSubItemOpen(item.route)}>
                            {item?.subItems?.map((subItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                sx={{
                                  display: "flex",
                                  paddingLeft: 4,
                                  padding: "0.2rem",
                                }}
                              >
                                <ListItemButton
                                  className={`listItemButtonactive ${selectedSubItem == subItem.route
                                    ? "subitem"
                                    : ""
                                    }`}
                                  sx={{
                                    marginLeft: "1.5rem",
                                    color: "#818181",
                                  }}
                                  component={NavLink}
                                  to={subItem.route}
                                  onClick={() => handleSubItemClick(subItem)}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                      gap: 8,
                                    }}
                                  >
                                    <div style={{ fontSize: "0.9rem" }}>
                                      {subItem.text}
                                    </div>
                                  </div>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </Collapse>
                        )}
                      </div>
                    );
                  })}
                </List>
                <Loader />
              </Drawer>
              <main
                style={{
                  width: `calc(100% - ${drawerWidth})`,
                  padding: "30px",
                  boxSizing: "border-box",
                }}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/Category" element={<CategoryManagementd />} />
                  <Route path="/UserManagement" element={<UserManagement />} />
                  <Route path="/UserProfile" element={<UserProfile />} />
                  <Route path="/PastEvent" element={<PastEvent />} />
                  <Route path="/TodayEvents" element={<TodayEvents />} />
                  <Route path="/FeaturedEvent" element={<FeaturedEvent />} />
                  <Route path="/Achievements" element={<Achievements />} />
                  <Route
                    path="/EventsManagement"
                    element={<EventsManagement />}
                  />
                  <Route path="/Eventdetails" element={<Eventdetails />} />
                  <Route path="/EventUpdate" element={<EventUpdate />} />
                  <Route path="/AddAchievements" element={<AddAchievements />} />
                  <Route path="/UpcomingEvent" element={<UpcomingEvent />} />
                  <Route path="/Subcategory" element={<Subcategory />} />
                  {/* <Route path="/EventType" element={<EventType />} /> */}
                  <Route path="/UserUpdate" element={<UserUpdate />} />
                  <Route path="/WithOutCheckInEvent" element={<WithOutCheckInEvent />} />
                  

                  
                  <Route
                    path="SetResetPassword"
                    element={<SetResetPassword />}
                  />
                </Routes>
              </main>
            </Box>
          </div>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  setIsLoggedIn={setIsLoggedIn}
                  setForgotNavigate={setForgotNavigate}
                />
              }
            />
            {forgotNavigate && (
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
            )}
            {forgotNavigate && (
              <Route
                path="/SetForgotPassword"
                element={<SetForgotPassword />}
              />
            )}
            <Route
              path="*"
              element={
                <Navigate to="/" replace={true} setIsLoggedIn={setIsLoggedIn} />
              }
            />
          </Routes>
        )}
      {/* </Router> */}
      <ToastContainer />
    </div>
  );
}

export default App;
