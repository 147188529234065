import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "../Style";
import Header from "../../Components/Header/Header";
import Icons from "../../Constants/Icons";
import { Text } from "react-native";
import Colors from "../../Constants/Colors";
import { CustomUploadProfilePic } from "../../Core/CustomUploadProfilePic";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import { onApiCallAdminCreateAchievement } from "../../Core/Apicall";

const AddAchievements = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { editAchievement, achievementData } = location?.state;


  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [uploadImage, setUploadImage] = useState(true);
  const [formData, setFormData] = useState({
    achievementName: achievementData?.achievementName ?? "",
    achievementDescription: achievementData?.achievementDesc ?? "",
    achievementNumber: achievementData?.achievementCount ?? "",
    achievementImage: achievementData?.achievementImage ?? null,
  });

  const [isValid, setIsValid] = useState({
    achievementName: true,
    achievementDescription: true,
    achievementNumber: true,
    achievementImage: true,
  });
  const handleSubmit = async () => {
    const newIsValid = {
      achievementName: formData.achievementName !== "",
      achievementDescription: formData.achievementDescription !== "",
      achievementNumber: formData.achievementNumber !== "",
      achievementImage: formData.achievementImage !== null,
    };

    setIsValid(newIsValid);
    const isFormValid = Object.values(newIsValid).every(Boolean);
    if (isFormValid) {
      try {
        dispatch(setLoadingTrue());
        let param = {
          achievementName: formData.achievementName,
          achievementDesc: formData.achievementDescription,
          achievementCount: JSON.parse(formData.achievementNumber),
          achievementImage: formData.achievementImage,
        };
        if (editAchievement) {
          param.achievementId = achievementData?.achievementId;
        }
        const res = await onApiCallAdminCreateAchievement(param);
        dispatch(setLoadingFalse());
        setShowSuccessPopup(true);
        setTimeout(() => {
          setFormData({
            achievementName: "",
            achievementDescription: "",
            achievementImage: null,
            achievementNumber: "",
          });
          setShowSuccessPopup(false);
          navigate("/Achievements");
        }, 2000);
      } catch (error) {
        console.log("errorerror", error);

        dispatch(setLoadingFalse());
      }
    }
    console.log("isFormValid", isFormValid);
  };
  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;
    if (fieldName === "achievementNumber") {
      value = value.replace(/[^0-9]/g, "");
    }
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleImageUploadEvent = async (event) => {
    dispatch(setLoadingTrue());
    const selectedImage = event.target.files[0];
    const formData = new FormData();
    formData.append("imageURL", selectedImage);
    formData.append("imageType", "AdminAchievementImages");
    formData.append("imageStoreId", "123456789");
    try {
      const data = await CustomUploadProfilePic(formData);
      setFormData((prevState) => ({
        ...prevState,
        achievementImage: data.location,
      }));

      dispatch(setLoadingFalse());
    } catch (error) {
      console.log("error", error);
      dispatch(setLoadingFalse());
    }
  };

  const uploadImageRemove = async () => {
    setFormData({
      ...formData,
      achievementImage: null,
    });
  };

  return (
    <div>
      <Header
        name={editAchievement == false ? "Achievement Add" : "Achievement Edit"}
      />

      <div className="Eventupdate" style={styles.Eventupdate}>
        <button className="colse_icon" onClick={() => navigate(-1)}>
          X
        </button>
        <div className="modal-content bg-Blue grid p-4 relative">
          <h2>
            {editAchievement == false ? "Achievement Add" : "Achievement Edit"}
          </h2>

          <div
            className={`Category_name   ${
              isValid.achievementName ? "valid" : "invalid"
            }`}
            style={{ marginBottom: "20px" }}
          >
            <input
              type="text"
              placeholder="Name"
              value={formData.achievementName}
              onChange={(e) => handleInputChange(e, "achievementName")}
            />
          </div>

          <div
            className={`Category_name   ${
              isValid.achievementDescription ? "valid" : "invalid"
            }`}
            style={{ marginBottom: "20px" }}
          >
            <input
              type="text"
              placeholder="Description"
              value={formData.achievementDescription}
              onChange={(e) => handleInputChange(e, "achievementDescription")}
            />
          </div>

          <div
            className={`Category_name   ${
              isValid.achievementNumber ? "valid" : "invalid"
            }`}
            style={{ marginBottom: "20px" }}
          >
            <input
              type="number"
              placeholder="Check In Number"
              value={formData.achievementNumber}
              onChange={(e) => handleInputChange(e, "achievementNumber")}
            />
          </div>

          <div
            className={`Category_upload ${
              isValid.achievementImage ? "valid" : "invalid"
            }`}
            style={{
              backgroundColor: Colors.dark_black,
              height: "100px",
              width: "100%",
              borderRadius: "15px",
              marginBottom: "30px",
            }}
          >
            {uploadImage && (
              <div
                className="absolute"
                style={{
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                  display: "block",
                  marginTop: "20px",
                }}
              >
                <Text style={[{ marginBottom: "50px" }, styles.labeld]}>
                  Achievement Photo
                </Text>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    className="uplode_data"
                    style={{
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                      }}
                      onChange={handleImageUploadEvent}
                      accept="image/*"
                    />
                    <img
                      src={Icons.Ic_addedimg}
                      style={{
                        height: "40px",
                        cursor: "pointer",
                        width: "40px",
                      }}
                    />
                  </div>
                  {formData.achievementImage != null && (
                    <div style={{ position: "relative" }}>
                      <img
                        src={formData.achievementImage}
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                        className="m-auto"
                      />
                      <img
                        src={Icons.delete}
                        alt="Category Preview"
                        onClick={() => uploadImageRemove()}
                        className="delete"
                        style={{
                          position: "absolute",
                          width: "20px",
                          cursor: "pointer",
                          height: "20px",
                          right: "-15px",
                          top: "-10px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              gap: "40px",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <div style={styles.eventsubmit} onClick={() => handleSubmit()}>
              Submit
            </div>
          </div>
          {showSuccessPopup && (
            <div className="success-popup">
              <div className="success_event" style={{ textAlign: "center" }}>
                <img src={Icons.Success} style={{ width: "50px" }} />
                <p>
                  {editAchievement
                    ? "Achievement Updated Successfully"
                    : "Achievement Added Successfully"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddAchievements;
