import React from 'react';

const EmptyData = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        marginTop:"40px",
        marginBottom:"40px",
        alignSelf: "stretch",
        color: "#FFF",
        fontWeight: 800,
        justifyContent: "center",
      }}
    >
      No Data Found
    </div>
  );
};

export default EmptyData;
