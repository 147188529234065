const initialState = null; // Initial state can be null to indicate no profile data

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROFILE_DATA":
      return action.payload; 
    default:
      return state;
  }
};

export default profileReducer;
