import React from "react";
import Icons from "../../Constants/Icons";
import EmptyData from "../../Components/EmptyData/EmptyData";
import Colors from "../../Constants/Colors";
import { useState } from "react";

const Customcategorycreen = ({
  currentCategories,
  onEditClick,
  hendDelete,
  handleSubcategory,
  categoryshow,
  onClickToggleStatusChange,
  Subcategoryshow,
}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [currentCategoryDataFOrAction, setCurrentCategoryDataFOrAction] =
    useState(null);
  const handleEditClick = (category) => {
    // console.log("categorycategory",category);
    onEditClick(category);
  };
  const handlaDelete = () => {
    hendDelete(currentCategoryDataFOrAction);
    setDeleteConfirmation(false);
  };
  const handlaDeleteopne = (data) => {
    setCurrentCategoryDataFOrAction(data);
    setDeleteConfirmation(true);
  };
  const handlesSubcategory = (category) => {
    handleSubcategory(category);
  };
  return (
    <div className="Categorie_s">
      <div
        style={{ maxHeight: "500px" }}
        className="scrollable-container transform_data "
      >
        {currentCategories?.map((category, index) => (
          <div key={index} className="transaction-item cursor-pointer">
            <div
              className="list-user-div-container border-bottom"
              style={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: "1px",
              }}
            >
              <div className="grid_colum grid_7" style={{ padding: "20px" }}>
                <div
                  className="fontSize16WithoutFlex category_w"
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      Subcategoryshow
                        ? category.artIconURL
                        : category.categoryImageURL ||
                          category.categoryImageURL === ""
                        ? category.artIconURL
                        : Icons.ic_AY_logo
                    }
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                    }}
                  />
                  {/* {Subcategoryshow ? ( <div>{category.categoryName}</div>):null} */}
                </div>

                <div className="fontSize16WithoutFlex category_w">
                  {Subcategoryshow ? category.artName : category.categoryName}
                </div>
                <div className="user_Status category_w">
                  <button
                    onClick={() => onClickToggleStatusChange(category)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: category.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: category.isEnabled
                        ? Colors.darkGreen
                        : Colors.darkRed,
                    }}
                  >
                    {category.isEnabled ? "Activate" : "Deactivate"}
                  </button>
                </div>

                <div className="fontSize16WithoutFlex category_w">
                  <img
                    src={Icons.edit}
                    onClick={() => handleEditClick(category)}
                    style={{
                      width: "20px",
                      cursor: "pointer",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </div>
                {categoryshow ? (
                  <div className="fontSize16WithoutFlex category_w">
                    <img
                      src={Icons.Subcategory}
                      onClick={() => handlesSubcategory(category)}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                  </div>
                ) : null}

                <div className="fontSize16WithoutFlex category_w">
                  <img
                    src={Icons.delete}
                    onClick={() => handlaDeleteopne(category)}
                    style={{
                      width: "20px",
                      cursor: "pointer",
                      filter: "brightness(0) invert(1)",
                    }}
                  />

                  {/* {deleteConfirmation && (
                    <div
                      className="modalcategory"
                      style={{
                        zIndex: 999999,
                      }}
                    >
                      <div
                        className="modal-content  "
                        style={{ width: "auto" }}
                      >
                        <p>Are you sure you want to delete this category?</p>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "50px",
                          }}
                        >
                          <div className="category_button">
                            <button
                              onClick={() => handlaDelete()}
                              style={{ width: "100px" }}
                            >
                              <div>Yes</div>
                            </button>
                          </div>
                          <div className="category_button">
                            <button
                              onClick={() => setDeleteConfirmation(null)}
                              style={{ background: "red", width: "100px" }}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {deleteConfirmation && (
        <div
          className="modalcategory"
          style={{
            zIndex: 999999,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: 'rgba(6, 101, 228, 1)',
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "auto",
              maxWidth: "800px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            <p>Are you sure you want to delete this sub category?</p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <button
                  onClick={() => handlaDelete()}
                style={{
                  width: "100px",
                  padding: "10px",
                  backgroundColor: "#d9534f", // Red color for "Yes"
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#c9302c")
                } // Darker red on hover
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#d9534f")
                } // Original red on leave
              >
                Yes
              </button>
              <button
                   onClick={() => setDeleteConfirmation(null)}
                style={{
                  backgroundColor: "#f0ad4e", // Muted orange for "No"
                  width: "100px",
                  padding: "10px",
                  color: "#fff", // White text for contrast
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#ec971f")
                } // Darker orange on hover
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#f0ad4e")
                } // Original orange on leave
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customcategorycreen;
