import Header from "../../Components/Header/Header";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import { Line } from "react-chartjs-2";
import Colors from "../../Constants/Colors";
import { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { Chart } from "chart.js/auto";
import CustomupcomingeventsScreen from "../../Components/CustomDashboardScreen/Upcomingevents";
import {
  apiCallDAshAdmin_ArtRunner_Count,
  apiCallDashArtLover,
  apiCallDashArtistCount,
  apiCallgetAllEvent,
  apiCalltoggleEventStatus,
  onAPiCallAdminGetDashboardEventCount,
} from "../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import EmptyData from "../../Components/EmptyData/EmptyData";

const optionsChart = {
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};
function Dashboard({  }) {
  const [artistChartData, setArtistChartData] = useState();
  const [artistChartDataTotal, setArtistChartDataTotal] = useState();
  const [userChartData, setUserChartData] = useState();
  const [userChartDataTotal, setUserChartDataTotal] = useState();
  const [artRunnerChartData, setArtRunnerChartData] = useState();
  const [artRunnerChartDataTotal, seArtRunnerChartDataTotal] = useState();
  const [userDropdownLabel, setUserDropdownLabel] = useState("Today");
  const [artistDropdownLabel, setArtistDropdownLabel] = useState("Today");
  const [artRunnerDropdownLabel, setArtRunnerDropdownLabel] = useState("Today");
  const [todayEventListData, setTodayEventListData] = useState();
  const [todayTotalCount, setTodayTotalCount] = useState(0);
  const [upcomingTotalCount, setUpcomingTotalCount] = useState(0);
  const [pastTotalCount, setPastTotalCount] = useState(0);
  const [featuredTotalCount, setFeaturedTotalCount] = useState(0);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const dropdownFilterData = ["Today", "This Week", "This Month"];

  useEffect(() => {
    onApiEventTypeList("Today");
    onApiCallDashBoardCount();
    onApiCallArtistChart("Today");
    onApiCallUserChart("Today");
    onApiCallArtRunnerChart("Today");
  }, []);

  const onApiCallDashBoardCount = async () => {
    dispatch(setLoadingTrue());
    try {
      const response = await onAPiCallAdminGetDashboardEventCount();
      setTodayTotalCount(response?.totalTodayEvent);
      setUpcomingTotalCount(response?.totalUpcomingEvent);
      setPastTotalCount(response?.totalPastEvent);
      setFeaturedTotalCount(response?.totalFeaturedEvent);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiEventTypeList = async (item, selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      status: null,
      weekSet: item,
      offset: selectedPage?.selected + 1 || 1,
      limit: 10,
    };
    try {
      const response = await apiCallgetAllEvent(param);
      setTodayEventListData(response.data);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.error("Error fetching event types:", error);
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallArtistChart = async (item) => {
    dispatch(setLoadingTrue());
    const param = {
      weekSet: item,
    };
    try {
      const res = await apiCallDashArtistCount(param);
      let initialTaxChartDataaa = {
        labels: ["1", "2"],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainOrange40,
            borderColor: Colors.mainOrange,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true,
          },
        ],
      };
      setArtistChartData(initialTaxChartDataaa);
      setArtistChartDataTotal(res?.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallUserChart = async (item) => {
    dispatch(setLoadingTrue());
    const param = {
      weekSet: item,
    };
    try {
      const res = await apiCallDashArtLover(param);
      let initialTaxChartDataaa = {
        labels: ["1", "2"],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainBlue40,
            borderColor: Colors.mainBlue,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true,
          },
        ],
      };
      setUserChartData(initialTaxChartDataaa);
      setUserChartDataTotal(res?.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallArtRunnerChart = async (item) => {
    dispatch(setLoadingTrue());
    const param = {
      weekSet: item,
    };
    try {
      const res = await apiCallDAshAdmin_ArtRunner_Count(param);
      let initialTaxChartDataaa = {
        labels: ["1", "2"],
        datasets: [
          {
            responsive: true,
            data: [0, res?.totalCount],
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: Colors.mainGreen01,
            borderColor: Colors.mainGreen,
            borderWidth: 1.5,
            lineTension: 0.4,
            fill: true,
          },
        ],
      };
      setArtRunnerChartData(initialTaxChartDataaa);
      seArtRunnerChartDataTotal(res?.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickUserDropdownChart = (item) => {
    setUserDropdownLabel(item);
    onApiCallUserChart(
      item == "This Week"
        ? "ThisWeekend"
        : item == "This Month"
        ? "ThisMonth"
        : "Today"
    );
  };
  const onClickArtistDropdownChart = (item) => {
    setArtistDropdownLabel(item);
    onApiCallArtistChart(
      item == "This Week"
        ? "ThisWeekend"
        : item == "This Month"
        ? "ThisMonth"
        : "Today"
    );
  };
  const onClickArtRunnerDropdownChart = (item) => {
    setArtRunnerDropdownLabel(item);
    onApiCallArtRunnerChart(
      item == "This Week"
        ? "ThisWeekend"
        : item == "This Month"
        ? "ThisMonth"
        : "Today"
    );
  };

  const hendEnabled = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled,
    };
    try {
      const res = await apiCalltoggleEventStatus(param);
      onApiEventTypeList("Today");
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickTabOpen = (item) => {
    const event = new CustomEvent("subTabEventListner", {
      detail: item,
    });
    window.dispatchEvent(event);
    navigate(item);
  };

  return (
    <div>
      <Header name={"Dashboard"} />

      <div
        style={{
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "grid",
              flexDirection: "row",

              justifyContent: "flex-start",
              gap: "1rem",
              fontSize: "1rem",
              gridTemplateColumns: " repeat(4,4fr)",
            }}
          >
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: Colors.borderColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                cursor: "pointer",
              }}
              onClick={() => onClickTabOpen("/TodayEvents")}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Today's event
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: Colors.darkBlue,
                }}
              >
                {todayTotalCount}
              </div>
            </div>
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: Colors.borderColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                cursor: "pointer",
              }}
              onClick={() => onClickTabOpen("/UpcomingEvent")}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Total Upcoming events
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: Colors.darkGreen,
                }}
              >
                {upcomingTotalCount}
              </div>
            </div>
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: Colors.borderColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                cursor: "pointer",
              }}
              onClick={() => onClickTabOpen("/PastEvent")}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Past Events
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: Colors.darkOrange,
                }}
              >
                {pastTotalCount}
              </div>
            </div>
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: Colors.borderColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                cursor: "pointer",
              }}
              onClick={() => onClickTabOpen("/FeaturedEvent")}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                Featured Events
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: Colors.darkRed,
                }}
              >
                {featuredTotalCount}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="User_show">
        <div
          style={{
            flex: "1",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            padding: "1rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="revenueMainDiv">User</div>
              <div className="revenueTaxActiveMainDivDropdown" style={{backgroundColor:"rgba(255, 255, 255, 0.1)"}}>
                <CustomDropdown
                  dropDownLabel={userDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={(item) => onClickUserDropdownChart(item)}
                />
              </div>
            </div>
            <div className="revenueValueDiv"> {userChartDataTotal}</div>
            {userChartData && (
              <Line
                height={50}
                style={{ width: "100%" }}
                data={userChartData}
                options={optionsChart}
              />
            )}
          </div>
        </div>

        <div
          style={{
            flex: "1",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            padding: "1rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="revenueMainDiv">Artists</div>
              <div className="revenueTaxActiveMainDivDropdown" style={{backgroundColor:"rgba(255, 255, 255, 0.1)"}} >
                <CustomDropdown
                  dropDownLabel={artistDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={(item) => onClickArtistDropdownChart(item)}
                />
              </div>
            </div>
            <div className="revenueValueDiv">{artistChartDataTotal}</div>
            {artistChartData && (
              <Line
                height={50}
                style={{ width: "100%" }}
                data={artistChartData}
                options={optionsChart}
              />
            )}
          </div>
        </div>

        <div
          style={{
            flex: "1",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            padding: "1rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="revenueMainDiv">ArtRunner</div>
              <div className="revenueTaxActiveMainDivDropdown" style={{backgroundColor:"rgba(255, 255, 255, 0.1)"}}>
                <CustomDropdown
                  dropDownLabel={artRunnerDropdownLabel}
                  items={dropdownFilterData}
                  onSelect={(item) => onClickArtRunnerDropdownChart(item)}
                />
              </div>
            </div>
            <div className="revenueValueDiv">{artRunnerChartDataTotal}</div>
            {artRunnerChartData && (
              <Line
                height={50}
                style={{ width: "100%" }}
                data={artRunnerChartData}
                options={optionsChart}
              />
            )}
          </div>
        </div>
      </div>

      <div className="margintop_20">
        <div className="">
          <div className="upcoming_list">
            <div
              className="margintop_20"
              style={{
                width: "100%",
                borderRadius: "16px",
                backgroundColor: Colors.mainDarkColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem",
                gap: "1rem",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <div className="astrologerText">Today Events List</div>
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  borderRadius: "8px",
                  border: Colors.darkBorderColor,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    backgroundColor: Colors.borderColor,
                    borderBottom: Colors.darkBorderColor,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "rgba(255, 255, 255, 0.5)",
                    justifyContent: "space-between",
                    padding: "1rem",
                  }}
                >
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "15%",
                    }}
                  >
                    image
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "15%",
                    }}
                  >
                    Name
                  </div>
                  <div
                    className="lastWeekEndDivGreyColor"
                    style={{
                      width: "13%",
                    }}
                  >
                    Events Name
                  </div>

                  <div
                    style={{
                      width: "20%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Date & times
                  </div>
                  <div
                    style={{
                      width: "10%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Edit
                  </div>
                  <div
                    style={{
                      width: "20%",
                    }}
                    className="lastWeekEndDivGreyColor"
                  >
                    Status
                  </div>
                </div>

                {todayEventListData?.length == 0 ? (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <EmptyData />
                  </div>
                ) : (
                  <div
                    style={{ maxHeight: "900px" }}
                    className="scrollable-container"
                  >
                    <CustomupcomingeventsScreen
                      hendEnabled={hendEnabled}
                      data={todayEventListData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
