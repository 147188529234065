// UserDetails.js
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../Components/Header/Header";
import Icons from "../../../Constants/Icons";
import UserProfileTab from "../UserTab/Usertab";
import Colors from "../../../Constants/Colors";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { apiCallGetUserDetails } from "../../../Core/Apicall";

const UserDetails = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    if (location.state) {
      onApiCallUserDetails();
    }
  }, []);

  const onApiCallUserDetails = async () => {
    dispatch(setLoadingTrue());
    const param = {
      userId: location.state.userId,
    };
    try {
      const res = await apiCallGetUserDetails(param);
      setUserDetails(res.data);
      console.log("user details", res.data)

      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div>
      <Header name={"User Details"} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
            className="margintop_20"
          >
            <div
              style={{
                borderRadius: "50%",
                overflow: "hidden",
                width: "70px",
                height: "70px",
              }}
            >
              <img
                style={{ width: "70px", height: "70px", borderRadius: "70px" }}
                src={userDetails?.image ?? Icons.Headshot}
              />
            </div>
            <div>{userDetails?.name}</div>
          </div>
          {/* {userDetails?.socialMedia.length != 0 && (
            <div style={{ marginTop: "10px" }}>
              {userDetails?.socialMedia?.map((link, index) => (
                <a
                  key={index}
                  href={link?.socialMediaLink}
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <img
                    src={link?.socialImageUrl}
                    style={{ width: "30px", marginLeft: "10px" }}
                    className="social-icon"
                  />
                </a>
              ))}
            </div>
          )} */}
        </div>
        <div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: "rgba(6, 101, 228, 0.3)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                width: "200px",
              }}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                followers
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: "white",
                }}
              >
                {userDetails?.followers}
              </div>
            </div>
            <div
              style={{
                flex: "1",
                borderRadius: "8px",
                backgroundColor: "rgba(6, 101, 228, 0.3)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem 1rem",
                gap: "1rem",
                width: "200px",
              }}
            >
              <div
                className="lastWeekEndDiv"
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  fontWeight: "500",
                }}
              >
                following
              </div>
              <div
                className="astrologerTextValueShow"
                style={{
                  color: "white",
                }}
              >
                {userDetails?.following}
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserProfileTab userId={location.state.userId} userDetails={userDetails} />
    </div>
  );
};

export default UserDetails;
