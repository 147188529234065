import React from "react";
import Header from "../../../Components/Header/Header";
import { useState, useEffect } from "react";
import Checkindetails from "./CustomEventdestailslist/Checkindetails";
import Hostdetails from "./CustomEventdestailslist/Hostdetails";
import Paymenthistiry from "./CustomEventdestailslist/Paymenthistory";
import Mediadetails from "./CustomEventdestailslist/ Mediadetails";
import { useLocation } from "react-router-dom";
import { apiCallgetEventDetails } from "../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
    setLoadingFalse,
    setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import Icons from "../../../Constants/Icons";
function Eventdetails() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    const [eventDetails, setEventDetails] = useState([]);
    const [hostdetails, setHostdetails] = useState();
    const tabs = ["Host details", "Media details", "Checked in details", 
        // "Payment history"
        
    ];
    const dispatch = useDispatch();
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        if (location.state) {
            onApiCallUserDetails();
        }
    }, []);

    const onApiCallUserDetails = async () => {
        dispatch(setLoadingTrue());
        const param = {
            eventId: location.state.eventId,
        };
        try {
            const response = await apiCallgetEventDetails(param);
            setEventDetails(response);
            setHostdetails(response.data)
            console.log("er", response)
            dispatch(setLoadingFalse());
        } catch (error) {
            dispatch(setLoadingFalse());
        }
    };
    return (
        <div>
            <Header name="Event Details" />
           
            <div className="user-profile-custom-container margintop_20">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        style={{
                            padding: "10px",
                            fontWeight: "400",
                            color:
                                index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                            borderBottom: index != activeTab ? "" : "1px solid #fff",
                            cursor: "pointer",
                        }}
                        className="fontSize18"
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </div>
                ))}</div>

            <div className="margintop_20">

                {activeTab === 0 && (
                    <div className="grid grid-cols-2 gap-7">
                        <Hostdetails eventDetails={eventDetails?.data} />
                    </div>
                )}
                {activeTab === 1 && (
                    <div className="grid grid-cols-2 gap-7">
                        <Mediadetails hostdetails={hostdetails?.eventMedia} onApiCallUserDetails={onApiCallUserDetails} />
                    </div>
                )}
                {activeTab === 2 && (
                    <div className="grid grid-cols-2 gap-7">
                        <Checkindetails eventDetails={eventDetails?.data} />
                    </div>
                )}
                {/* {activeTab === 3 && (
                    <div className="grid grid-cols-2 gap-7">
                        <Paymenthistiry />
                    </div>
                )} */}

            </div>


        </div>
    )
}
export default Eventdetails;