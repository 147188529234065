import rootReducer from "./Reducer";
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'; // Import persistStore and persistReducer
import storage from 'redux-persist/lib/storage'; // Import storage (localStorage by default for web)
const persistConfig = {
  key: 'root', // key is required, it represents where in your persistence layer you'll store your data.
  storage, // here we define what storage we are using.
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);
export { store, persistor };
