import React, { useEffect, useRef, useState } from "react";
import "../../../../../../src/Components/DropDown/CustomDropDownStyle.css";
import Icons from "../../../../../Constants/Icons";
import { apiCallGetAdminInterestCategoryList } from '../../../../../Core/Apicall';
import { useDispatch } from "react-redux";
import {
    setLoadingFalse,
    setLoadingTrue,
} from "../../../../../Config/Actions/loadingActions";

function CustomDropdown({ onSelect, dropDownLabel, customLabelStyle }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [cur_section, setCurSection] = useState('')
    const [items, setEventType] = useState();

    const dispatch = useDispatch();
    useEffect(() => {
        onApiCallCategoryList();
      }, []);
    
      const onApiCallCategoryList = async (selectedPage) => {
        dispatch(setLoadingTrue());
        const param = {
          status: null,
          offset: selectedPage?.selected + 1 || 1,
          limit: 15,
        };
        try {
          const res = await apiCallGetAdminInterestCategoryList(param);
          setEventType(res);
          dispatch(setLoadingFalse());
        } catch (error) {
          dispatch(setLoadingFalse());
        }
      };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);
        setIsOpen(false);
        setCurSection(item)
        onSelect(item);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <div
            onMouseLeave={() => handleMouseLeave()}
            onClick={toggleDropdown}
            className="custom-dropdown"
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 26,
                }}
            >
                <div
                    onClick={toggleDropdown}
                    style={customLabelStyle}
                    className="custom-dropdown2"
                >
                    {dropDownLabel}
                </div>
                <div style={{ display: "flex" }}>
                    <img className="downArrowIcon" alt="" src={Icons.ic_down} />
                </div>
            </div>
            {isOpen && (
                <div style={{ position: "absolute", width: "100%", left: "0px" }}>
                    <div style={{ height: 10 }}></div>

                    <ul style={{}} className="dropdown-menu">
                        {items.categories.map((item) => (
                            <li className={item === cur_section ? "active" : ""} key={item} onClick={() => handleItemClick(item)}>
                                {item.categoryName}
                            </li>

                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default CustomDropdown;
