import React, { useEffect, useState } from "react";
import Colors from "../../../../Constants/Colors";
import { apiCallPostUserAchievementsEvent } from "../../../../Core/Apicall";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const Achievement = ({ userId }) => {
  const isLoading = useSelector((state) => state.isLoading);
  const [achievementsData, setAchievementData] = useState([]);
  const [totalAchievementCount, setTotalAchievementCOunt] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallAchievement();
  }, []);

  const onApiCallAchievement = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallPostUserAchievementsEvent(param);
      setAchievementData(res.data);
      setTotalAchievementCOunt(res.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div className="achievements">
      <div className="upcoming_list">
        <div
          className="margintop_20"
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div className="astrologerText">Achievements</div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "8px",
              border: Colors.darkBorderColor,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                backgroundColor: Colors.borderColor,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "28%",
                }}
              >
                image
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "22%",
                }}
              >
                Name
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "13%",
                }}
              >
                Events Name
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "25%",
                }}
              ></div>
              <div
                style={{
                  width: "30%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Description
              </div>
              {/* <div
                style={{
                  width: "20%",
                }}
                className="lastWeekEndDivGreyColor"
              >
                Status
              </div> */}
            </div>

            <div
              style={{ maxHeight: "350px" }}
              className="scrollable-container"
            >
              {!isLoading && achievementsData.length == 0 ? (
                <EmptyData />
              ) : (
                achievementsData.map((item, index) => (
                  <div key={index}>
                    <div
                      style={{
                        alignSelf: "stretch",
                        flex: "1",
                        borderBottom: Colors.darkBorderColor,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "rgba(255, 255, 255, 0.5)",
                        justifyContent: "space-between",
                        padding: "1rem",
                      }}
                    >
                      <div
                        className="lastWeekEndDivGreyColor"
                        style={{
                          width: "28%",
                        }}
                      >
                        <img src={item.imageUrl} style={{ width: "50px" }} />
                      </div>
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "22%",
                        }}
                      >
                        {item.eventCreatorName}
                      </div>
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "13%",
                        }}
                      >
                        {item.eventName}
                      </div>
                      <div
                        className="fontSize16WithoutFlex"
                        style={{
                          width: "25%",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "30%",
                        }}
                        className="fontSize16WithoutFlex"
                      >
                        {item.description}
                      </div>
                      {/* <div
                      style={{
                        width: "20%",
                      }}
                      className="fontSize16WithoutFlex"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="user_Status"
                      >
                        <button
                          onClick={() => onClickAchievementStatusChange(item, index)}
                          className="button"
                          style={{
                            cursor: "pointer",
                            backgroundColor: item.isEnabled
                              ? Colors.mainGreen01
                              : Colors.mainRed01,
                            color: item.isEnabled
                              ? Colors.darkGreen
                              : Colors.darkRed,
                          }}
                        >
                          {item.isEnabled ? "Activate" : "Deactivate"}
                        </button>
                      </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {achievementsData.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(totalAchievementCount / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallAchievement}
          />
        </div>
      )}
    </div>
  );
};

export default Achievement;
