import React from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import styles from "../../../Style";
import EmptyData from "../../../../Components/EmptyData/EmptyData";
const Checkindetails = ({ eventDetails }) => {
    return (

        <div>
            <div className="achievements">
                <div className="upcoming_list">
                    <div className="margintop_20"
                        style={styles.menu_ic}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "0.5rem",
                                }}
                            >

                                <div className="astrologerText">Checked in details</div>

                            </div>

                        </div>
                        <div
                            style={{
                                alignSelf: "stretch",
                                flex: "1",
                                borderRadius: "8px",
                                border: Colors.darkBorderColor,
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    flex: "1",
                                    backgroundColor: Colors.borderColor,
                                    borderBottom: Colors.darkBorderColor,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "rgba(255, 255, 255, 0.5)",
                                    justifyContent: "space-between",
                                    padding: "1rem",
                                }}
                            >


                                <div
                                    className="lastWeekEndDivGreyColor"
                                    style={{
                                        width: "13%",
                                    }}
                                >
                                    User Name
                                </div>

                                <div
                                    style={{
                                        width: "20%",
                                    }}
                                    className="lastWeekEndDivGreyColor"
                                >
                                    Contact
                                </div>
                                <div
                                    style={{
                                        width: "20%",
                                    }}
                                    className="lastWeekEndDivGreyColor"
                                >
                                    Venue Purchase
                                </div>
                                <div
                                    className="lastWeekEndDivGreyColor"
                                    style={{
                                        width: "20%",
                                    }}
                                >
                                    Note
                                </div>
                                <div
                                    className="lastWeekEndDivGreyColor"
                                    style={{
                                        width: "20%",
                                    }}
                                >
                                    Guest Join
                                </div>

                            </div>

                            <div
                                style={{ maxHeight: "350px" }}
                                className="scrollable-container"
                            >   {eventDetails.checkInEventData.length == 0 ? (
                                <EmptyData />
                            ) : (

                                eventDetails.checkInEventData.map((itmes, index) => (
                                <div key={index}>
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            borderBottom: Colors.darkBorderColor,
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            color: "rgba(255, 255, 255, 0.5)",
                                            justifyContent: "space-between",
                                            padding: "1rem",
                                        }}
                                    >


                                        <div
                                            className="fontSize16WithoutFlex"
                                            style={{
                                                width: "13%",
                                            }}
                                        >
                                            {itmes.userName}

                                        </div>


                                        <div style={{ display: "block", gap: "4px" }} className="fontSize16WithoutFlex user_name">
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "4px",
                                                }}

                                                className="fontSize16WithoutFlex"
                                            >

                                                <img
                                                    style={{
                                                        width: "1rem",
                                                        objectFit: "fill",
                                                        height: "1rem",
                                                        display: "inline-block",
                                                        marginRight: "2px",
                                                    }}
                                                    src={Icons.ic_sms}
                                                    alt="new"
                                                />

                                                <div className="user_text">
                                                    {itmes.userEmail}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "4px",
                                                    marginTop: "4px"
                                                }}

                                                className="fontSize16WithoutFlex"
                                            >

                                                <img
                                                    className="icon_style_list "
                                                    alt=""
                                                    src={Icons.ic_call}
                                                />
                                                <div className="user_text">
                                                    {itmes.userMobile}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                width: "20%",
                                            }}
                                            className="fontSize16WithoutFlex"
                                        >
                                            <div style={{ cursor: "pointer" }} className="user_Status" >
                                                <div className="user_text">
                                                    {itmes.venuePurchase ?? "-"}
                                                </div>

                                                {/* <button

                                                        className="button"
                                                        style={{
                                                            cursor: "pointer",
                                                            backgroundColor:
                                                                itmes.Status === "Success"
                                                                    ? Colors.mainGreen01
                                                                    : itmes.Status === "Failed"
                                                                        ? Colors.mainOrange01
                                                                        : Colors.mainRed01,
                                                            color:
                                                                itmes.Status === "Success" || itmes.Status === "Failed " || itmes.Status === "InProgress"
                                                                    ? Colors.darkGreen
                                                                    : itmes.Status === "Pending"
                                                                        ? "yellow"
                                                                        : Colors.darkRed

                                                        }}

                                                    >
                                                        {itmes.Status}
                                                    </button> */}
                                            </div>


                                        </div>
                                        <div
                                            className="fontSize16WithoutFlex "
                                            style={{
                                                width: "20%",
                                            }}
                                        > <div className="user_text">
                                                {itmes.note ?? "-"}
                                            </div>

                                        </div>
                                        <div
                                            className="fontSize16WithoutFlex "
                                            style={{
                                                width: "20%",
                                            }}
                                        > <div className="user_text">
                                                {itmes.guestJoin ?? "-"}
                                            </div>

                                        </div>
                                    </div>


                                </div>)))}
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default Checkindetails;