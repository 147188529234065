import React from "react";
import Icons from "../../Constants/Icons";
import { Link, useNavigate } from "react-router-dom";
import Colors from "../../Constants/Colors";


const CustomEventeScreen = ({ currentUsers, hendEnabled }) => {
  const navigate = useNavigate();
  const handleEditClick = (currentUsers) => {
    navigate("/EventUpdate", {
      state: {
        eventId: currentUsers.eventId,
        eventTypeId: currentUsers.eventTypeId,
      },
    })
    console.log("typeID",currentUsers)
  }
  return (

    <div
      style={{ maxHeight: "500px" }}
      className="scrollable-container transform_data"
    >

      {currentUsers?.map((currentUsers, index) => (
        <div key={index} className="transaction-item cursor-pointer">
          <div
            className="list-user-div-container border-bottom"
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: "1px",

            }}
          >
            <div className="grid_colum grid_7" style={{ padding: "20px" }}>
              <div style={{ cursor: "pointer", width: "10%" }} onClick={() =>
                navigate("/Eventdetails", {
                  state: {
                    eventId: currentUsers.eventId,
                  },
                })
              } className="fontSize16WithoutFlex user_name">{currentUsers.categoryName == "" ? '-':currentUsers.categoryName}</div>

              <div onClick={() =>
                navigate("/Eventdetails", {
                  state: {
                    eventId: currentUsers.eventId,
                  },
                })
              } className="fontSize16WithoutFlex user_name" style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }}  >
                <img src={currentUsers.eventCreatorImg === null ? Icons.ic_AY_logo : currentUsers.eventCreatorImg}
                  style={{ width: "50px", height: "50px", borderRadius: "25px", objectFit: "cover" }} />
                <div className="user_text" style={{ cursor: "pointer" }} onClick={() =>
                  navigate("/Eventdetails", {
                    state: {
                      eventId: currentUsers.eventId,
                    },
                  })
                }><div className="user_text">{currentUsers.eventCreatorName}</div>
                  <div className="user_text">{currentUsers.eventCreatorUserName}</div>
                </div>
              </div>

              <div style={{ display: "block", gap: "4px" }} className="user_name">
                <div style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "4px" }} onClick={() =>
                  navigate("/Eventdetails", {
                    state: {
                      eventId: currentUsers.eventId,
                    },
                  })
                } className="fontSize16WithoutFlex">
                  <img
                    style={{
                      width: "1rem",
                      objectFit: "fill",
                      height: "1rem",
                      display: "inline-block",
                      marginRight: "2px",

                    }}
                    src={Icons.ic_sms}
                    alt="new"
                  /><div className="user_text">
                    {currentUsers.eventCreatorEmail}
                  </div>
                </div>
                <div style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "4px" }} onClick={() =>
                  navigate("/Eventdetails", {
                    state: {
                      eventId: currentUsers.eventId,
                    },
                  })
                } className="fontSize16WithoutFlex"> <img
                    className="icon_style_list"
                    alt=""
                    src={Icons.ic_call}
                  /><div className="user_text">
                    {currentUsers.eventCreatorMobile}
                  </div></div>
              </div>
              <div style={{ cursor: "pointer" }} onClick={() =>
                navigate("/Eventdetails", {
                  state: {
                    eventId: currentUsers.eventId,
                  },
                })
              } className="fontSize16WithoutFlex user_name" >{currentUsers.eventDescription}</div>
              <div onClick={() =>
                navigate("/Eventdetails", {
                  state: {
                    eventId: currentUsers.eventId,
                  },
                })
              } className="fontSize16WithoutFlex user_name" style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }}  >

                <div className="user_text" style={{ cursor: "pointer" }} onClick={() =>
                  navigate("/Eventdetails", {
                    state: {
                      eventId: currentUsers.eventId,
                    },
                  })
                }><div className="user_text">{currentUsers.eventName}</div><div className="user_text">{currentUsers.eventTypeName}</div>
                </div>
              </div>
              <div onClick={() =>
                navigate("/Eventdetails", {
                  state: {
                    eventId: currentUsers.eventId,
                  },
                })
              } className="fontSize16WithoutFlex user_name" style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }}  >

                <div className="user_text" style={{ cursor: "pointer" }} onClick={() =>
                  navigate("/Eventdetails", {
                    state: {
                      eventId: currentUsers.eventId,
                    },
                  })
                }><div className="user_text">{currentUsers.venueName}</div><div className="user_text">{currentUsers.venueAddress}</div>
                </div>
              </div>
              <div style={{ width: "10%" }}>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(currentUsers)}
                  style={{
                    width: "20px",
                    cursor: "pointer",
                    filter: "brightness(0) invert(1)",
                  }}
                />
              </div>
              <div style={{ cursor: "pointer" }} className="user_Status user_name" >
                <button
                  onClick={() => hendEnabled(currentUsers, index)}
                  className="button"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      currentUsers.action === "Accepted"
                        ? Colors.mainGreen01
                        : currentUsers.action === "Rejected"
                          ? Colors.mainOrange01
                          : Colors.mainRed01,
                    color: currentUsers.isEnabled
                      ? Colors.darkGreen
                      : Colors.darkRed,
                  }}
                >
                  {currentUsers.isEnabled ? "Activate" : "Deactivate"}
                </button>
              </div>


            </div>
          </div>
        </div>
      ))}
    </div>

  );
};

export default CustomEventeScreen;
