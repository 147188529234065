import React, { useEffect } from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiCallPostGetArtLog } from "../../../../Core/Apicall";
import { setLoadingFalse, setLoadingTrue } from "../../../../Config/Actions/loadingActions";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const ArtLog = ({ userId }) => {
  const isLoading = useSelector((state) => state.isLoading);
  const [artLogs, setArtLog] = useState([]);
  const [totalArtLogCount, setTotalArtLogCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    onApiCallArtLog();
  }, []);

  const onApiCallArtLog = async (selectedPage) => {

    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      status: null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallPostGetArtLog(param);
      setArtLog(res?.data)
      setTotalArtLogCount(res.totalCount);

      console.log("resresresresresresresres", res);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }

  };

  const hendEnabled = (selected) => {
    // setartLog(artLogs.map(host =>
    //     host.id === selected.id
    //         ? { ...host, Status: !host.Status }
    //         : host
    // ));
    // console.log("fdg", selected)
  };

  return (
    <div>
      <div className="achievements">
        <div className="upcoming_list">
          <div
            className="margintop_20"
            style={{
              width: "100%",
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              gap: "1rem",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div className="astrologerText">ArtLog</div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  backgroundColor: Colors.borderColor,
                  borderBottom: Colors.darkBorderColor,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "rgba(255, 255, 255, 0.5)",
                  justifyContent: "space-between",
                  padding: "1rem",
                }}
              >
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "15%",
                  }}
                >
                  image
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "15%",
                  }}
                >
                  Name
                </div>
                <div
                  className="lastWeekEndDivGreyColor"
                  style={{
                    width: "13%",
                  }}
                >
                  Events Name
                </div>

                <div
                  style={{
                    width: "20%",
                  }}
                  className="lastWeekEndDivGreyColor"
                >
                  Date & times
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                  className="lastWeekEndDivGreyColor"
                >
                  Status
                </div>
              </div>
              <div
                style={{ maxHeight: "350px" }}
                className="scrollable-container"
              >
                {!isLoading && artLogs.length == 0 ? (
                  <EmptyData />
                ) : (
                  artLogs.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{
                          alignSelf: "stretch",
                          flex: "1",
                          borderBottom: Colors.darkBorderColor,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color: "rgba(255, 255, 255, 0.5)",
                          justifyContent: "space-between",
                          padding: "1rem",
                        }}
                      >
                        <div
                          className="lastWeekEndDivGreyColor"
                          style={{
                            width: "15%",
                          }}
                        >
                          <img src={item.eventImages} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                        </div>
                        <div
                          className="fontSize16WithoutFlex"
                          style={{
                            width: "15%",
                          }}
                        >
                          {item.categoryName}
                        </div>
                        <div
                          className="fontSize16WithoutFlex"
                          style={{
                            width: "13%",
                          }}
                        >
                          {item.eventName}
                        </div>

                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            gap: "0.25rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "0.5rem",
                            }}
                          >
                            <img
                              className="icon_style_list"
                              alt=""
                              src={Icons.ic_calendar}
                            />
                            <div style={{}} className="itemTextDisplay">
                              {item.eventStartDate + " , " + item.eventStartTime}
                            </div>
                          </div>
                          {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <img
                            className="icon_style_list"
                            alt=""
                            src={Icons.ic_location}
                          />
                          <div className="itemTextDisplay">
                            {item.birthPlace == "" ? "-" : item.birthPlace}
                          </div> */}
                          {/* </div> */}
                        </div>
                        <div
                          style={{
                            width: "20%",
                          }}
                          className="fontSize16WithoutFlex"
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="user_Status"
                          >
                            <button
                              onClick={() => hendEnabled(item, index)}
                              className="button"
                              style={{
                                cursor: "pointer",
                                backgroundColor: item.isEnabled
                                  ? Colors.mainGreen01
                                  : Colors.mainRed01,
                                color: item.isEnabled
                                  ? Colors.darkGreen
                                  : Colors.darkRed,
                              }}
                            >
                              {item.isEnabled ? "Activate" : "Deactivate"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {artLogs.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(totalArtLogCount / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallArtLog}
          />
        </div>
      )}
    </div>
  );
};

export default ArtLog;
