import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../../../Components/Header/Header";
import Icons from "../../../../../Constants/Icons";
import Colors from "../../../../../Constants/Colors";
import { useDispatch } from "react-redux";
import {
    setLoadingFalse,
    setLoadingTrue,
} from "../../../../../Config/Actions/loadingActions";
import { apiCallGetUserDetails, onAPiCallupdateUserProfile } from "../../../../../Core/Apicall";
import styles from "../../../../Style";
import CustomDropdown from "../../../../../Components/DropDown/CustomDropdown";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import moment from "moment";
import { CustomUploadProfilePic } from "../../../../../Core/CustomUploadProfilePic";
import { useNavigate } from "react-router-dom";


const UserUpdate = () => {
    const [uploadImage, setuploadImage] = useState(true);
    const [imagePreview, setImagePreview] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const navigate = useNavigate();
    const [formdata, setFormdata] = useState({
        UserType: "",
        Name: "",
        UserName: "",
        Mobile: "",
        Description: "",
        BirthDate: "",
        UserTypevd: true,
        Namevd: true,
        UserNamevd: true,
        Mobilevd: true,
        Descriptionvd: true,
        BirthDatevd: true,
        Imagesvd: true
    });

    const location = useLocation();
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState();

    useEffect(() => {
        if (userDetails) {
            setFormdata((prevState) => ({
                ...prevState,
                UserType: userDetails.userType,
                Name: userDetails.name,
                UserName: userDetails.userName,
                Mobile: userDetails.mobile,
                Description: userDetails.description,
                BirthDate: moment(userDetails.birthDate, "DD-MM-YYYY").format("MM-DD-YYYY"),
            }));
            setImagePreview(userDetails.image)
            if (userDetails.image === null) {
                setuploadImage(true);
                setImagePreview(null);
            } else {
                setuploadImage(false);
            }
        }

    }, [userDetails]);
    useEffect(() => {
        if (location.state) {
            onApiCallUserDetails();
        }

    }, [location.state,]);

    const onApiCallUserDetails = async () => {
        dispatch(setLoadingTrue());
        const param = {
            userId: location.state.userId,
        };
        try {
            const res = await apiCallGetUserDetails(param);
            setUserDetails(res.data);
            console.log("user details", res.data);
            dispatch(setLoadingFalse());
        } catch (error) {
            console.error('Failed to fetch user details', error);
            dispatch(setLoadingFalse());
        }
    };

    const handleSubmit = async (e) => {

        let isValidForm = true;
        if (formdata.UserType === null || formdata.UserType === "") {
            setFormdata((prevState) => ({
                ...prevState,
                UserTypevd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                UserTypevd: true,
            }));
        }

        if (formdata.Name === null || formdata.Name === "") {
            setFormdata((prevState) => ({
                ...prevState,
                Namevd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                Namevd: true,
            }));
        }

        if (formdata.UserName === null || formdata.UserName === "") {
            setFormdata((prevState) => ({
                ...prevState,
                UserNamevd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                UserNamevd: true,
            }));
        }

        if (formdata.Mobile === null || formdata.Mobile === "") {
            setFormdata((prevState) => ({
                ...prevState,
                Mobilevd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                Mobilevd: true,
            }));
        }

        if (formdata.Description === null || formdata.Description === "") {
            setFormdata((prevState) => ({
                ...prevState,
                Descriptionvd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                Descriptionvd: true,
            }));
        }

        if (formdata.BirthDate === null || formdata.BirthDate === "") {
            setFormdata((prevState) => ({
                ...prevState,
                BirthDatevd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                BirthDatevd: true,
            }));
        }

        if (imagePreview === null) {
            setFormdata((prevState) => ({
                ...prevState,
                Imagesvd: false,
            }));
            isValidForm = false;
        } else {
            setFormdata((prevState) => ({
                ...prevState,
                Imagesvd: true,
            }));
        }

        if (isValidForm) {
            const param = {
                userId: userDetails.userId,
                name: formdata.Name,
                userName: formdata.UserName,
                profileImageURL: imagePreview,
                description: formdata.Description,
                userType: formdata.UserType,
                birthDate: moment(formdata.BirthDate).format("DD/MM/YYYY"),

            };
            dispatch(setLoadingTrue());
            try {
                const res = await onAPiCallupdateUserProfile(param);
                console.log("user details", res);
                dispatch(setLoadingFalse());
                setShowSuccessPopup(true);
                setTimeout(() => {
                    setShowSuccessPopup(false);
                    navigate("/UserManagement")
                }, 2000);
            } catch (error) {
                console.error("Error in onApiCallupdata:", error);
                dispatch(setLoadingFalse());
            }
        }
    };

    const handleImageUpload = async (event) => {
        dispatch(setLoadingTrue());
        const selectedImage = event.target.files[0];

        const formData = new FormData();

        formData.append("imageURL", selectedImage);
        formData.append("imageType", "IntrestCategoryImages");
        formData.append("imageStoreId", "123456789");

        try {
            const data = await CustomUploadProfilePic(formData);
            setImagePreview(data.location);
            setuploadImage(false);
            dispatch(setLoadingFalse());
        } catch (error) {
            dispatch(setLoadingFalse());
        }
    };


    const handleImageDelete = () => {
        setImagePreview("")
        setuploadImage(true);
    };
    console.log("formdata.imagePreview", formdata.imagePreview)
    return (
        <div>
            <Header name="User" />
            <div className='Eventupdate' style={styles.Eventupdate}>
                <div
                    className="modal-content bg-Blue grid p-4 relative"
                >

                    <h2 >
                        User Update
                    </h2>

                    <div className={`Category_name Subcategory_drop  ${formdata.UserType ? 'valid' : 'invalid'}`} style={styles.CustomDrop}>
                        <CustomDropdown
                            dropDownLabel={formdata.UserTypevd === null ? <div style={{ color: "rgb(129, 129, 129)" }}>Repeat Type</div> : formdata.UserType}
                            items={["Artist", "ArtRunner", "ArtLover"]}
                            color="blue"
                            onSelect={(selectedItem) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    UserType: selectedItem
                                }));
                            }}
                            customLabelStyle={{ fontWeight: "400", color: "white", width: "100%" }}
                        />
                    </div>
                    <div className={`Category_name ${formdata.Namevd ? 'valid' : 'invalid'}`} style={{ marginBottom: "20px", }}>
                        <input
                            type="text"
                            value={formdata.Name}
                            onChange={(e) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    Name: e.target.value
                                }));
                            }}
                            placeholder="Name"
                        />
                    </div>
                    <div className={`Category_name ${formdata.UserNamevd ? 'valid' : 'invalid'}`} style={{ marginBottom: "20px", }}>
                        <input
                            type="text"
                            value={formdata.UserName}
                            onChange={(e) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    UserName: e.target.value
                                }));
                            }}
                            placeholder="User Name"
                        />
                    </div>
                    <div className={`Category_name ${formdata.Descriptionvd ? 'valid' : 'invalid'}`} style={{ marginBottom: "20px", height: "50px" }}>
                        <input
                            className='Event_input'
                            type="text"
                            value={formdata.Description}
                            onChange={(e) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    Description: e.target.value
                                }));
                            }}
                            placeholder="Description"
                        />
                    </div>
                    <div className={`Category_name ${formdata.Mobilevd ? 'valid' : 'invalid'}`} style={{ marginBottom: "20px" }} >
                        <input
                            type="number"
                            value={formdata.Mobile}
                            onChange={(e) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    Mobile: e.target.value
                                }));
                            }}
                            placeholder="Mobile"
                        />
                    </div>
                    <div className={`Category_name  ${formdata.BirthDatevd ? 'valid' : 'invalid'}`} style={{ marginBottom: "20px", width: "100%", zIndex: "2" }}>

                        <DatePicker
                            selected={formdata.BirthDate}
                            minimumDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Start Date"
                            showPopperArrow={false}
                            onChange={(date) => {
                                setFormdata((prevState) => ({
                                    ...prevState,
                                    BirthDate: date
                                }));
                            }}
                            showTimeSelect={false}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                                <div style={styles.startDate}>
                                    <img
                                        src={Icons.ic_date}
                                        style={{ height: "20px" }}
                                        alt="calendar icon"
                                    />
                                    <div className="fontSize16">

                                        {formdata.BirthDate ? (
                                            format(formdata.BirthDate, 'dd/MM/yyyy')
                                        ) : (
                                            <div style={styles.customtext_ad}>Birth Date</div>
                                        )}

                                    </div>
                                </div>
                            }
                        />

                    </div>
                    <div className={`Category_upload  ${formdata.Imagesvd ? 'valid' : 'invalid'}`} style={{ backgroundColor: Colors.dark_black, height: "70px", width: "80px", borderRadius: "15px" }}>
                        {uploadImage && (
                            <div
                                className="absolute"

                            >
                                <div className="uplode_data" style={{ width: "40px", height: "40px" }}>
                                    <input
                                        type="file"
                                        style={{ height: "40px", width: "40px" }}
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                    <img

                                        src={Icons.Ic_addedimg}
                                        style={{ height: "40px", width: "40px" }}
                                    /></div>
                            </div>
                        )}
                        {imagePreview && (
                            <div className="w-full text-center relative " style={{ position: "relative" }}>
                                <img
                                    src={Icons.delete}
                                    alt="Category Preview"
                                    onClick={() => handleImageDelete()}
                                    className="delete"
                                    style={{
                                        position: "absolute",
                                        width: "20px",
                                        cursor: "pointer",
                                        height: "20px",
                                        objectFit: "contain",
                                        filter: "brightness(0) invert(1)",
                                        right: "-15px",
                                        top: "-15px"
                                    }}
                                />
                                <img
                                    src={imagePreview}
                                    alt="Category Preview"
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "cover",
                                    }}
                                    className="m-auto"
                                />
                            </div>
                        )}
                    </div>

                    <div style={{ display: "flex", gap: "40px", maxWidth: "500px", margin: "auto" }} >
                        <div
                            style={styles.eventsubmit}
                            onClick={handleSubmit}
                        >
                            Submit
                        </div>
                    </div>

                </div>
            </div>
            {showSuccessPopup && (
                <div className="success-popup">
                    <div className='success_event' style={{ textAlign: "center" }}>
                        <img src={Icons.Success} style={{ width: "50px" }} />
                        <p >User Updated Successfully</p>
                        {/* <button onClick={handlePopupClose}>X</button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserUpdate;
