import React, { useState, useEffect } from "react";
import Icons from "../../Constants/Icons";
import CustomDropdown from "../../Components/DropDown/CustomDropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import { CustomUploadProfilePic } from "../../Core/CustomUploadProfilePic";
import { apiCallAddSubCategoryAdd } from "../../Core/Apicall";

const AddSubcategory = ({
  shows,
  setshowcategory,
  editData,
  handleClose,
  categoryItemSelect,
  pageRefresh,
}) => {
  const [subcategoryName, setSubcategoryName] = useState("");
  const [uploadImage, setUploadImage] = useState(true);
  const [imagePreview, setImagePreview] = useState("");
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
console.log("editDataeditData",editData);
  useEffect(() => {
    if (editData !== undefined) {
      setImagePreview(editData?.categoryImageURL || "");
      setSubcategoryName(editData?.categoryName || "");
      setUploadImage(true);
    }
  }, [editData]);

  const uploadImageRemove = () => {
    setImagePreview("");
    setUploadImage(true);
  };

  const handleSubcategoryNameChange = (e) => {
    setSubcategoryName(e.target.value);
  };

  const closeModal = () => {
    setSubcategoryName("");
    setImagePreview("");
    setUploadImage(true);
    setshowcategory(false);
  };

  const handleImageUpload = async (event) => {
    console.log("ds");
    dispatch(setLoadingTrue());
    const selectedImage = event.target.files[0];
    console.log("selectedImage",selectedImage);
    const formData = new FormData();

    formData.append("imageURL", selectedImage);
    formData.append("imageType", "IntrestCategoryImages");
    formData.append("imageStoreId", "123456789");

    try {
      const data = await CustomUploadProfilePic(formData);
      console.log("data",data);
      setImagePreview(data.location);
      setUploadImage(false);
      dispatch(setLoadingFalse());
    } catch (error) {
      console.log("error",error);
      dispatch(setLoadingFalse());
    }
  };

  const handleSave = async () => {
    if (subcategoryName.trim() === "") {
      alert("Please enter a subcategory name");
      return;
    }
    if (imagePreview === "") {
      alert("Please upload an image");
      return;
    }
    dispatch(setLoadingTrue());
    let param = {
      categoryId: categoryItemSelect?.categoryId,
      artName: subcategoryName,
      artIconURL: imagePreview,
    };

    // if (editData) {
    //   param = {
    //     ...param,
    //     categoryId: editData.categoryId,
    //   };
    // }
    try {
      //   if (editData) {
      await apiCallAddSubCategoryAdd(param);
      //   } else {
      //     await apiCallCreateInterestCategory(param);
      //   }
      setSubcategoryName("");
      setImagePreview("");
      setUploadImage(true);
      closeModal()
      pageRefresh();
      handleClose();
      setshowcategory(false);
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div
      className={`modalcategory ${shows ? "display-block" : "display-none"}`}
    >
      <div className="modal-content bg-Blue grid p-4 relative">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <h2>Add New Subcategory</h2>
        <div className="Category_name">
          <input
            type="text"
            value={subcategoryName}
            onChange={handleSubcategoryNameChange}
            placeholder="Enter Subcategory Name"
          />
        </div>
        <div className="Category_upload">
          {uploadImage ? (
            <div className="absolute">
              <div className="uplode_data">
                <input
                  type="file"
                  style={{ height: "50px", width: "50px" }}
                  onChange={handleImageUpload}
                  accept="image/*"
                />
                <img
                  src={Icons.uplod}
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
            </div>
          ) : (
            <div className="w-full text-center relative ">
              <img
                src={Icons.delete}
                alt="Category Preview"
                onClick={uploadImageRemove}
                className="delete"
                style={{
                  position: "absolute",
                  width: "20px",
                  cursor: "pointer",
                  height: "20px",
                  objectFit: "cover",
                }}
              />
              <img
                src={imagePreview}
                alt="Category Preview"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                className="m-auto"
              />
            </div>
          )}
        </div>
        <div className="category_button">
          <button
            style={{ width: "100px", borderRadius: "6px" }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSubcategory;
