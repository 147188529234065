import {
  StyleSheet, View
} from 'react-native';
import Colors from '../Constants/Colors';

const styles = StyleSheet.create({
  Eventupdate: {
    //     backgroundColor: Colors.dark_black,
    padding: "40px",
    paddingTop: "10px",
    borderRadius: "10px"

  },
  CustomDrop: {
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  Eventdate: {
    display: "flex",
    gap: "20px"

  },
  line: {
    width: "100%",
    height: "2px",
    backgroundColor: Colors.dark_black,
    marginBottom: "20px"
  },
  checkbox: {
    marginRight: 10,
  },
  label: {
    fontSize: "14px",
    color: "#818181"
  },
  labeld: {
    fontSize: "14px",
    color: "white",
    width: "200px"
  },
  labelds: {
    fontSize: "14px",
    color: "white",
  },
  eventsubmit: {
    cursor: "pointer",
    backgroundColor: Colors.Reddrak,
    padding: "15px",
    borderRadius: "5px",
    textAlign: "center",
    width: "100%"
  }
  ,
  startDate: {
    display: "flex",
    paddingLeft: "20px",
    alignItems: "center",
    paddingTop: "10px",
    gap: "10px",


  },
  TicketDate: {
    display: "flex",
    paddingLeft: "20px",
    alignItems: "center",
    paddingTop: "10px",
    gap: "10",
    justifyContent: "space-between",
    width: "560px"
  },
  timePicker: {
    position: "absolute",
    zIndex: "999"
  },
  customtext_ad: {
    color: "#818181"
  },
  menu_ic: {
    width: "100%",
    borderRadius: "16px",
    backgroundColor: Colors.mainDarkColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "1rem",
    gap: "1rem",
    boxSizing: "border-box"
  },
  Hostdetails:{
    display: "grid",
    gridTemplateColumns:"repeat(3 ,3fr)",
    width:"100%",
    gap:"20px"
  },
  Hostdetails1:{
    display: "grid",
    gridTemplateColumns:"repeat(2 ,3fr)",
    width:"100%",
    gap:"20px"
  }
});

export default styles;