import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import { useNavigate } from "react-router-dom";

const RenderItemAchievements = ({
  data,
  onClickStatusChange,
  onClickYesDelete,
}) => {
  const navigate = useNavigate();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteItemData, setDeleteItemData] = useState(null);
  const handleEditClick = (data) => {
    navigate("/AddAchievements", {
      state: {
        achievementData: data,
        editAchievement: true,
      },
    });
  };

  const onClickDelete = (item) => {
    setDeleteItemData(item);
    setDeleteConfirmation(true);
  };

  return (
    <div>
      <div style={{ maxHeight: "500px" }} className="scrollable-container">
        {data?.map((item, index) => (
          <div key={index}>
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "15%",
                }}
              >
                <img
                  src={item?.achievementImage}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                className="fontSize16WithoutFlex"
                style={{
                  width: "18%",
                }}
              >
                {item?.achievementName}
              </div>
              <div
                className="fontSize16WithoutFlex"
                style={{
                  width: "20%",
                }}
              >
                {item?.achievementDesc}
              </div>
              <div
                className="fontSize16WithoutFlex"
                style={{
                  width: "13%",
                }}
              >
                {item?.achievementCount}
              </div>

              <div style={{ width: "7%" }}>
                <img
                  src={Icons.edit}
                  onClick={() => handleEditClick(item)}
                  style={{
                    width: "20px",
                    cursor: "pointer",
                    filter: "brightness(0) invert(1)",
                  }}
                />
              </div>

              <div
                style={{
                  width: "10%",
                }}
                className="fontSize16WithoutFlex"
              >
                <img
                  src={Icons.delete}
                  onClick={() => onClickDelete(item)}
                  style={{
                    width: "20px",
                    cursor: "pointer",
                    filter: "brightness(0) invert(1)",
                  }}
                />
              </div>

              <div
                style={{
                  width: "10%",
                }}
                className="fontSize16WithoutFlex"
              >
                <div style={{ cursor: "pointer" }} className="user_Status">
                  <button
                    onClick={() => onClickStatusChange(item, index)}
                    className="button"
                    style={{
                      cursor: "pointer",
                      backgroundColor: item.isEnabled
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      color: item.isEnabled ? Colors.darkGreen : Colors.darkRed,
                    }}
                  >
                    {item.isEnabled ? "Activate" : "Deactivate"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* {deleteConfirmation && (
        <div
          className="modalcategory"
          style={{
            zIndex: 999999,
          }}
        >
          <div className="modal-content  " style={{ width: "auto" }}>
            <p>Are you sure you want to delete this category?</p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
              }}
            >
              <div className="category_button">
                <button
                  onClick={() => {setDeleteConfirmation(false); onClickYesDelete(deleteItemData)}}
                  style={{ width: "100px" }}
                >
                  <div>Yes</div>
                </button>
              </div>
              <div className="category_button">
                <button
                  onClick={() => {
                    setDeleteItemData(null);
                    setDeleteConfirmation(false);
                  }}
                  style={{ background: "red", width: "100px" }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {deleteConfirmation && (
        <div
          className="modalcategory"
          style={{
            zIndex: 999999,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: 'rgba(6, 101, 228, 1)',
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              width: "auto",
              maxWidth: "800px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            <p>Are you sure you want to delete this achievement?</p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <button
                onClick={() => {
                  setDeleteConfirmation(false);
                  onClickYesDelete(deleteItemData);
                }}
                style={{
                  width: "100px",
                  padding: "10px",
                  backgroundColor: "#d9534f", // Red color for "Yes"
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#c9302c")
                } // Darker red on hover
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#d9534f")
                } // Original red on leave
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setDeleteItemData(null);
                  setDeleteConfirmation(false);
                }}
                style={{
                  backgroundColor: "#f0ad4e", // Muted orange for "No"
                  width: "100px",
                  padding: "10px",
                  color: "#fff", // White text for contrast
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#ec971f")
                } // Darker orange on hover
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#f0ad4e")
                } // Original orange on leave
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RenderItemAchievements;
