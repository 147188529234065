import React, { useEffect, useRef, useState } from "react";
import "./CustomDropDownStyle.css";
import Icons from "../../Constants/Icons";
function CustomDropdown({ items, onSelect, dropDownLabel, customLabelStyle }) {
  const [isOpen, setIsOpen] = useState(false);
  const [cur_section, setCurSection] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [selectedItem, setSelectedItem] = useState(dropDownLabel);

  useEffect(() => {
    setSelectedItem(dropDownLabel);
  }, [dropDownLabel]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    onSelect(item);
    setCurSection(item);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      onMouseLeave={() => handleMouseLeave()}
      onClick={toggleDropdown}
      className="custom-dropdown"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 26,
        }}
      >
        <div
          onClick={toggleDropdown}
          style={customLabelStyle}
          className="custom-dropdown2"
        >
          {selectedItem}
          {/* {dropDownLabel} */}
        </div>
        <div
          className={` ${isOpen ? "validmenuround" : "menuround"}`}
          style={{ display: "flex" }}
        >
          <img className="downArrowIcon" alt="" src={Icons.ic_down} />
        </div>
      </div>
      {isOpen && (
        <div style={{ position: "absolute", width: "100%", left: "0px" }}>
          <div style={{ height: 10 }}></div>

          <ul style={{}} className="dropdown-menu">
            {items.map((item) => (
              <li
                className={selectedItem == "By Status" ? "": item === cur_section ? "active" : ""}
                key={item}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
