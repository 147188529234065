import React, { useState, useRef, useEffect } from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import { apiCallDeleteEventMedia } from "../../../../Core/Apicall";
import { useDispatch } from "react-redux";

import {
    setLoadingFalse,
    setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const Mediadetails = ({ hostdetails, onApiCallUserDetails }) => {

    const [medias, setmedia] = useState(hostdetails);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const videoRefs = useRef({});
    const dispatch = useDispatch();
    const handlePlay = (id) => {
        const videoRef = videoRefs.current[id];
        if (videoRef) {
            videoRef.play();
            setIsPlaying(prevState => ({
                ...prevState,
                [id]: true
            }));
            setCurrentVideoId(id);
            console.log("Video play id:", id,);
        }
    };

    const handlePause = (id) => {
        const videoRef = videoRefs.current[id];
        if (videoRef) {
            videoRef.pause();
            setIsPlaying(prevState => ({
                ...prevState,
                [id]: false
            }));
            setCurrentVideoId(id);
            console.log("Video pause id:", id);
        }
    };
    const handleDelete = async (data) => {
        dispatch(setLoadingTrue());
        const param = {
            mediaId: data.mediaId,
        };
        try {
            const res = await apiCallDeleteEventMedia(param);
            console.log("media", res)
            const updatedEventData = medias.map((event) => {
                const updatedEvent = { ...event };
                if (updatedEvent.media) {
                    updatedEvent.media = updatedEvent.media.filter(
                        (mediaItem) => mediaItem.mediaId !== data.mediaId
                    );
                }
                return updatedEvent;
            });
            setmedia(updatedEventData);

            console.log("media", updatedEventData)
            dispatch(setLoadingFalse());
        } catch (error) {
            dispatch(setLoadingFalse());
        }

    }

    return (
        <div style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
            boxSizing: "border-box"
        }}>

            <div style={{ textAlign: "left", backgroundColor: "rgba(255, 255, 255, 0.1)", color: "rgba(255, 255, 255, 0.9)", padding: "20px 10px", borderRadius: "10px" }} className='lastWeekEndDivGreyColor'>
                Media details
            </div>

            {medias.length == 0 ? (
                <div style={{ textAlign: "center", width: "100%" }}>
                    <EmptyData />
                </div>
            ) : (
                medias?.map((media) => (
                    <>   <div style={{ display: "inline-block", flexWrap: "wrap", gap: "20px", padding: "20px" }}>
                        {media?.mediaUrl?.length > 0 && (
                            <div key={media.id} className="media_menu" style={{ marginBottom: "10px", borderRadius: "10px", float: "left", marginRight: "10px" }}>
                                <img src={media.mediaUrl} alt="User Media" style={{ height: "160px", width: "160px", objectFit: "cover", borderRadius: "10px" }} />

                                <img
                                    className="delete_media"
                                    src={Icons.delete}
                                    onClick={() => handleDelete(media)}
                                    style={{ width: "20px", cursor: "pointer", filter: "brightness(0) invert(1)" }}
                                    alt="Delete Icon"
                                />
                            </div>)}
                        {media?.thumbnailUrl?.length > 0 && (
                            <div key={media.id} className="media_menu" style={{ marginBottom: "0px", borderRadius: "10px", float: "left", marginRight: "10px" }}>
                                <video
                                    src={media.thumbnailUrl}
                                    loop
                                    ref={(el) => (videoRefs.current[media.id] = el)}
                                    style={{ height: "327px", width: "160px", objectFit: "cover", borderRadius: "10px" }}
                                />
                                <img
                                    className="delete_media"
                                    src={Icons.delete}
                                    onClick={() => handleDelete(media)}
                                    style={{ width: "20px", cursor: "pointer", filter: "brightness(0) invert(1)" }}
                                    alt="Delete Icon"
                                />
                                {!isPlaying[media.id] && (
                                    <div onClick={() => handlePlay(media.id)} style={{ position: "absolute", cursor: "pointer", right: "10px", bottom: "10px" }}>
                                        <img src={Icons.playbutton} style={{ height: "25px" }} />
                                    </div>
                                )}
                                {isPlaying[media.id] && (
                                    <div onClick={() => handlePause(media.id)} style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%" }}>

                                    </div>
                                )}
                            </div>
                        )}</div>

                    </>

                )))}

        </div>
    )
}
export default Mediadetails;