import React from "react";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallGetUserWiseAllEvent,
  apiCall_Admin_ToggleEventStatus,
  onAPiCallsearchUserEvent
} from "../../../../Core/Apicall";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

const ALLeventList = ({ userId }) => {
  const isLoading = useSelector((state) => state.isLoading);
  const statusDropdownItem = ["All", "Activate", "Deactivate"];
  const RecurringDropdownItem = [
    "All",
    "Weekly",
    "BiWeekly",
    "Monthly",
    "I Don't Known",
    "Custom",
  ];
  const DateDropdownItem = ["All", "Today", "This Week", "This Month"];

  const [searchValue, setSearchValue] = useState("");

  const [selectDateDropValue, setDateDropValue] = useState("Select Date");
  const [selectRecuringValue, setRecuringDropValue] =
    useState("Select Recurring");
  const [statusDropValue, setStatusDropValue] = useState("Select Status");

  const [eventsListArray, setEventsListArray] = useState([]);
  const [allEventsListArray, setAllEventsListArray] = useState([]);
  const [totalEventCountPage, setTotalEventCount] = useState(0);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallAllEventGet();
  }, [selectDateDropValue, selectRecuringValue, statusDropValue]);

  const onApiCallAllEventGet = async (selectedPage) => {
    dispatch(setLoadingTrue());
    const param = {
      userId: userId,
      repeatType:
        selectRecuringValue == "All" ||
          selectRecuringValue == "Select Recurring"
          ? ""
          : selectRecuringValue,
      weekSet:
        selectDateDropValue == "All" || selectDateDropValue == "Select Date"
          ? ""
          : selectDateDropValue == "This Week"
            ? "ThisWeekend"
            : selectDateDropValue == "This Month"
              ? "ThisMonth"
              : selectDateDropValue == "I Don't Known" ? "IDontKnow" : selectDateDropValue,
      status:
        statusDropValue === "All" || statusDropValue == "Select Status"
          ? null
          : statusDropValue === "Activate"
            ? true
            : statusDropValue === "Deactivate"
              ? false
              : null,
      offset: selectedPage?.selected + 1 || 1,
      limit: 15,
    };
    try {
      const res = await apiCallGetUserWiseAllEvent(param);
      setEventsListArray(res.data);
      setAllEventsListArray(res.data);
      setTotalEventCount(res.totalCount);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const navigate = useNavigate();
  const handleInputChange = (e) => {
    // setDateDropValue("Select Date");
    // setRecuringDropValue("Select Recurring");
    // setStatusDropValue("Select Status");
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const searchFilterFunction = async (text) => {
    if (text) {
      const newData = allEventsListArray.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setEventsListArray(newData);
    } else {
      setEventsListArray(allEventsListArray);
    }
    console.log("userId",userId)
    const param = {
      userId: userId,
      searchQuery: text,
      offset: 1,
      limit: 30
    };

    try {
      const res = await onAPiCallsearchUserEvent(param);
      console.log("res  serach evts", res.data);
      setEventsListArray(res.data);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSelectRecurring = (recurring) => {
    setRecuringDropValue(recurring);
  };

  const handleSelectDate = (date) => {
    setDateDropValue(date);
  };

  const handleSelectStatus = (status) => {
    setStatusDropValue(status);
  };

  const handleEnabled = async (data) => {
    dispatch(setLoadingTrue());
    const param = {
      eventId: data.eventId,
      isEnabled: !data.isEnabled,
    };
    try {
      const res = await apiCall_Admin_ToggleEventStatus(param);
      setEventsListArray((prevEvents) =>
        prevEvents.map((item) =>
          item.eventId === data.eventId
            ? { ...item, isEnabled: !item.isEnabled }
            : item
        )
      );
      setAllEventsListArray((prevEvents) =>
        prevEvents.map((item) =>
          item.eventId === data.eventId
            ? { ...item, isEnabled: !item.isEnabled }
            : item
        )
      );
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleEdit = (item) => {
    setEditData(item);
    navigate("/EventUpdate", {
      state: {
        eventId: item.eventId,
      },
    });
    console.log("event data", userId)
  };
  const handleupdate = () => {
    dispatch(setLoadingTrue());
    setTimeout(() => {
      dispatch(setLoadingFalse());
    }, 3000);
  };
  return (
    <div>
      <div
        style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        className="astrologers-list-parent"
      >
        <div className="frame-parent2">
          <div className="custom-box-user">
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={selectDateDropValue}
                items={DateDropdownItem}
                color="blue"
                onSelect={handleSelectDate}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>
            <div
              style={{ width: "200px" }}
              className="custom-container-dropdown-div"
            >
              <CustomDropdown
                dropDownLabel={selectRecuringValue}
                items={RecurringDropdownItem}
                color="blue"
                onSelect={handleSelectRecurring}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>
            <div className="custom-container-dropdown-div">
              <CustomDropdown
                dropDownLabel={statusDropValue}
                items={statusDropdownItem}
                color="blue"
                onSelect={handleSelectStatus}
                customLabelStyle={{
                  fontWeight: "400",
                  color: "white",
                }}
              />
            </div>

            {/* <div className="custom-box-search-div">
              <img className="custom-image" alt="" src={Icons.ic_search} />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="margintop_20"
        style={{
          width: "100%",
          borderRadius: "16px",
          backgroundColor: Colors.mainDarkColor,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "1rem",
          gap: "1rem",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "0.5rem",
          }}
        >
          <div className="astrologerText">Events</div>
          {/* <img
            onClick={handleupdate}
            src={Icons.ic_refresh}
            className="updateicone"
          /> */}
        </div>

        <div
          style={{
            alignSelf: "stretch",
            flex: "1",
            borderRadius: "8px",
            border: Colors.darkBorderColor,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              backgroundColor: Colors.borderColor,
              borderBottom: Colors.darkBorderColor,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "rgba(255, 255, 255, 0.5)",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <div
              className="lastWeekEndDivGreyColor"
              style={{
                width: "28%",
              }}
            >
              image
            </div>
            <div
              className="lastWeekEndDivGreyColor"
              style={{
                width: "22%",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "20%",
              }}
              className="lastWeekEndDivGreyColor"
            >
              Date
            </div>
            <div
              style={{
                width: "20%",
              }}
              className="lastWeekEndDivGreyColor"
            >
              Recurring Type
            </div>
            <div
              className="lastWeekEndDivGreyColor"
              style={{
                width: "20%",
              }}
            >
              Edit
            </div>
            <div
              style={{
                width: "20%",
              }}
              className="lastWeekEndDivGreyColor"
            >
              Status
            </div>
          </div>

          <div style={{ maxHeight: "400px" }} className="scrollable-container">
            {!isLoading && eventsListArray.length == 0 ? (
              <EmptyData />
            ) : (
              eventsListArray.map((item, index) => (
                <div key={index}>
                  <div
                    style={{
                      alignSelf: "stretch",
                      flex: "1",
                      borderBottom: Colors.darkBorderColor,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      color: "rgba(255, 255, 255, 0.5)",
                      justifyContent: "space-between",
                      padding: "1rem",
                    }}
                  >
                    <div
                      className="lastWeekEndDivGreyColor"
                      style={{
                        width: "28%",
                      }}
                    >
                      <img src={item.eventImages} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                    </div>
                    <div
                      className="fontSize16WithoutFlex"
                      style={{
                        width: "22%",
                      }}
                    >
                      {item.eventName}
                    </div>

                    <div
                      style={{
                        width: "20%",
                      }}
                      className="fontSize16WithoutFlex"
                    >
                      {item.eventStartDate}
                    </div>
                    <div
                      style={{
                        width: "20%",
                      }}
                      className="fontSize16WithoutFlex"
                    >
                      {item.repeatType === "" ? item.repeatType : "-"}
                    </div>
                    <div
                      className="fontSize16WithoutFlex"
                      style={{
                        width: "20%",
                      }}
                    >
                      <img
                        src={Icons.edit}
                        onClick={() => handleEdit(item, index)}
                        style={{
                          width: "20px",
                          cursor: "pointer",
                          filter: "brightness(0) invert(1)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "20%",
                      }}
                      className="fontSize16WithoutFlex"
                    >
                      <div style={{ cursor: "pointer" }} className="user_Status">
                        <button
                          onClick={() => handleEnabled(item, index)}
                          className="button"
                          style={{
                            cursor: "pointer",
                            backgroundColor: item.isEnabled
                              ? Colors.mainGreen01
                              : Colors.mainRed01,
                            color: item.isEnabled
                              ? Colors.darkGreen
                              : Colors.darkRed,
                          }}
                        >
                          {item.isEnabled ? "Activate" : "Deactivate"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )))}
          </div>
        </div>
      </div>
      {eventsListArray.length != 0 && (
        <div className="custompagination">
          <CustomPagination
            pageCount={Math.ceil(totalEventCountPage / 15)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallAllEventGet}
          />
        </div>
      )}
    </div>
  );
};

export default ALLeventList;
