// reducers/index.js
import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";
import profileReducer from "./profileReducer";

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  isProfileData: profileReducer,
  
});

export default rootReducer;
