import React, { useState, useEffect } from "react";
// import { getaddCategory, postEditCategory } from "../../../Core/Apicall";
import Icons from "../../Constants/Icons";
import { CustomUploadProfilePic } from "../../Core/CustomUploadProfilePic";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../Config/Actions/loadingActions";
import {
  apiCallCreateInterestCategory,
  apiCallEditInterestCategory,
} from "../../Core/Apicall";

function Modal({ show, handleClose, pageRefresh, editData }) {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [uploadImage, setuploadImage] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (editData != undefined) {
      setImagePreview(editData?.categoryIconURL);
      setNewCategoryName(editData?.categoryName);
      setuploadImage(false);
    }
  }, [editData]);

  const uploadImageRemove = () => {
    setImagePreview("");
    setuploadImage(true);
  };

  const handleImageUpload = async (event) => {
    dispatch(setLoadingTrue());
    const selectedImage = event.target.files[0];

    const formData = new FormData();

    formData.append("imageURL", selectedImage);
    formData.append("imageType", "IntrestCategoryImages");
    formData.append("imageStoreId", "123456789");

    try {
      const data = await CustomUploadProfilePic(formData);
      setImagePreview(data.location);
      setuploadImage(false);
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const handleSubmit = async () => {
    if (newCategoryName.trim() === "") {
      alert("Please enter a category name");
      return;
    }
    if (imagePreview === "") {
      alert("Please upload an image");
      return;
    }
    dispatch(setLoadingTrue());
    let param = {
      categoryName: newCategoryName,
      categoryIconURL: imagePreview,
    };

    if (editData) {
      param = {
        ...param,
        categoryId: editData.categoryId,
      };
    }
    try {
      if (editData) {
        await apiCallEditInterestCategory(param);
      } else {
        await apiCallCreateInterestCategory(param);
      }
      setNewCategoryName("");
      setImagePreview("");
      setuploadImage(true);
      pageRefresh();
      handleClose();
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  const closeModal = () => {
    setNewCategoryName("");
    setImagePreview("");
    setuploadImage(true);
    handleClose();
  };
  return (
    <div
      style={{}}
      className={`modalcategory ${show ? "display-block" : "display-none"}`}
    >
      <div className="modal-content bg-Blue grid p-4 relative">
        <span className="close " onClick={closeModal}>
          &times;
        </span>
        <h2 className="text-white font-medium text-xl text-center my-3">
          {editData ? "Edit Category" : "Add New Category"}
        </h2>
        <div className="Category_name">
          <input
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Enter Category Name"
          />
        </div>

        <div className="Category_upload">
          {uploadImage && (
            <div style={{ cursor: "pointer" }} className="absolute">
              <div style={{ cursor: "pointer" }} className="uplode_data">
                <input
                  type="file"
                  style={{ height: "50px", cursor: "pointer", width: "50px" }}
                  onChange={handleImageUpload}
                  accept="image/*"
                />
                <img
                  src={Icons.uplod}
                  style={{ height: "50px", cursor: "pointer", width: "50px" }}
                />
              </div>
            </div>
          )}
          {imagePreview && (
            <div className="w-full text-center relative ">
              <img
                src={Icons.delete}
                alt="Category Preview"
                onClick={() => uploadImageRemove()}
                className="delete"
                style={{
                  position: "absolute",
                  width: "20px",
                  cursor: "pointer",
                  height: "20px",
                  objectFit: "cover",
                }}
              />
              <img
                src={imagePreview}
                alt="Category Preview"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                }}
                className="m-auto"
              />
            </div>
          )}
        </div>
        <div className="category_button">
          <button
            style={{ width: "100px", borderRadius: "6px" }}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
